import {Component, computed, Inject, input, Input, signal, ViewChild} from '@angular/core';
import {AlgoliaService, CustomProductListFilter} from '@core/services/algolia.service';
import {history} from 'instantsearch.js/es/lib/routers';
import {ActivatedRoute} from '@angular/router';
import {SearchPageResultsComponent} from './components/search-page-results/search-page-results.component';
import {DOCUMENT, Location, NgIf, NgOptimizedImage} from '@angular/common';
import qs from 'qs';
import {SearchPageTitleComponent} from './components/search-page-title/search-page-title.component';
import {DynamicModule} from 'ng-dynamic-component';
import {TopFilterComponent} from './components/top-filter/top-filter.component';
import {TopFilterActiveComponent} from './components/top-filter-active/top-filter-active.component';
import {InstantsearchToggleComponent} from '@layout/components/instantsearch-toggle/instantsearch-toggle.component';
import {LoadingIconComponent} from '@layout/components/loading-icon/loading-icon.component';
import {SadWineglassIconComponent} from '@layout/components/sad-wineglass-icon/sad-wineglass-icon.component';
import {AlgoliaSearchHelper} from 'algoliasearch-helper';
import {AlgoliaFacetFilter} from '@core/types/algolia-filter';
import {InstantSearchOptions} from 'instantsearch.js/es/types';
import {ChangeEvent, InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {InstantSearchConfigureComponent} from '@core/instantsearch/components/instant-search-configure.component';
import {InstantSearchSearchBoxComponent} from '@core/instantsearch/components/instant-search-search-box.component';
import {InstantSearchStatsComponent} from '@core/instantsearch/components/instant-search-stats.component';
import {RoutingService} from '@core/services/routing.service';
import {InstantsearchSortByComponent} from '@layout/components/instantsearch-sort-by/instantsearch-sort-by.component';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  standalone: true,
  imports: [SearchPageTitleComponent, DynamicModule, TopFilterComponent, TopFilterActiveComponent, InstantsearchToggleComponent, SearchPageResultsComponent, LoadingIconComponent, NgOptimizedImage, SadWineglassIconComponent, InstantSearchComponent, InstantSearchConfigureComponent, InstantSearchSearchBoxComponent, InstantSearchStatsComponent, NgIf, InstantsearchSortByComponent]
})
export class SearchPageComponent {

  configuration = {hitsPerPage: 48} as any;
  voucherList = input<boolean>(false);
  filtersSignal = this.algoliaService.algoliaFacets;
  sortsSignal = computed(() => this.algoliaService.algoliaSorts().map(d => ({label: d?.title, value: d?.indexName})));
  topFilterHeight = signal<string>('50px')
  hasLoadedHits = signal(false);
  hits = signal(0);
  currentAlgoliaIndex = signal(this.algoliaService.defaultIndex);

  ready = computed(() => (this.routingService.isFirstRoute() && !this.voucherList()) || this.hasLoadedHits());

  public config: InstantSearchOptions;
  private _instantSearch: InstantSearchComponent;
  private _filter: CustomProductListFilter | undefined;

  @ViewChild('searchResults') searchResults: SearchPageResultsComponent;

  @Input() title: string;
  @Input() sortBy: (a: any, b: any) => number;
  @Input() hideDiscountFilter: boolean = true;
  @Input() hideProductDefaultCta: boolean = false;

  @Input()
  set hitsPerPage(value: number) {
    this.configuration.hitsPerPage = value;
  }

  public get filter() {
    return this._filter;
  }

  constructor(
    private algoliaService: AlgoliaService,
    private route: ActivatedRoute,
    private location: Location,
    @Inject(DOCUMENT) private document: any,
    protected routingService: RoutingService
  ) {
  }

  get instantSearch(): InstantSearchComponent {
    return this._instantSearch;
  }

  @ViewChild('instantSearch')
  set instantSearch(value: InstantSearchComponent) {
    this._instantSearch = value;
  }

  @Input()
  public set filter(filter) {
    this._filter = filter;
    this.configuration.filters = this.getFilters();
  }

  topFilterHeightChange(newHeight: string) {
    if (newHeight && newHeight !== this.topFilterHeight()) {
      this.topFilterHeight.set(newHeight);
    }
  }

  shownFilters(filters: AlgoliaFacetFilter[]) {
    return filters.filter(filter => !filter.hidden);
  }

  setCurrentIndex(indexName: string) {
    this.currentAlgoliaIndex.set(indexName);
  }

  protected hashPartToFilter(part: string) {
    const [attribute, value] = part?.split('=') ?? [];

    const whitelistedAttributes = ['default_price', 'field_grapes', 'facet_types', 'country', 'producer'];

    if (whitelistedAttributes.indexOf(attribute) === -1 || !value) {
      return [];
    }
    return value.split(',').map(d => {
      return {
        attribute,
        name: decodeURI(d)
      };
    });
  }

  protected getFilterByHash() {
    const parts = (this.route.snapshot.fragment?.split('&') ?? []).map(d => this.hashPartToFilter(d));
    return parts.reduce((p, c) => {
      p.push(...c);
      return p;
    }, []);
  }

  filterToUiState(filters: AlgoliaFacetFilter[], filterParam: CustomProductListFilter | undefined, indexName?: string, sortByIndexName?: string) {
    const state: any = {};
    if ((!filterParam && !sortByIndexName) || !indexName) {
      return undefined;
    }
    const filter = {...filterParam};
    const types = [...(filter.types ?? [])];
    const hashFilter = this.getFilterByHash();
    if (hashFilter.length > 0) {

      types.push(...hashFilter);
    }

    if (!types) {
      return undefined;
    }
    state.refinementList = {};
    state.toggle = {};
    if (sortByIndexName) {
      state.sortBy = sortByIndexName;
    }
    for (const type of types) {
      const facet = filters.find(value => value?.algoliaAttribute === type?.attribute);
      if (type?.attribute) {
        if (facet?.instantSearchType === 'toggle') {
          const value = type?.name;
          if (value) {
            state.toggle[type.attribute] = value;
          }
        } else {
          if (!state?.refinementList[type.attribute]) {
            state.refinementList[type.attribute] = [];
          }
          state?.refinementList[type.attribute].push(type?.name);
        }
      }
    }
    return {[indexName]: state};
  }

  protected getFilters() {
    const objectIds = this.filter?.objectIds || [];
    const newConfig = {...this.configuration};

    if (objectIds.length > 0) {
      const filter = objectIds
        .map((value) => `visma_id: '${(value as any)?.vismaId}'`)
        .join(' OR ');
      newConfig.filters = `(${filter})`;
    } else {
      newConfig.filters = '';
    }

    return newConfig.filters;
  }

  // https://www.algolia.com/doc/api-reference/widgets/instantsearch/angular/#widget-param-routing;
  private getHistory() {
    const historyObject = history();
    const _this = this;
    historyObject.onUpdate = function (callback) {
      const _this2 = this as any;

      _this2._onPopState = function (event: any) {
        if (_this2.writeTimer) {
          clearTimeout(_this2.writeTimer);
          _this2.writeTimer = undefined;
        }

        const routeState = event.state; // At initial load, the state is read from the URL without update.
        // Therefore the state object is not available.
        // In this case, we fallback and read the URL.

        if (!routeState) {
          callback(_this2.read());
        } else {
          callback(routeState);
        }
      };

      // Mads: We dont use popstate and it give a error. SUPER-1248
      // window.addEventListener('popstate', this._onPopState);
    };
    historyObject.write = function (routeState, delay?: number) {
      const self = this as any;
      delete routeState?.['Products']?.page;
      if (!(_this.instantSearch?.instantSearchInstance as any)?.started) {
        return;
      }
      const url = this.createURL(routeState);
      const title = self.windowTitle && self.windowTitle(routeState);

      if (self.writeTimer) {
        clearTimeout(self.writeTimer);
      }

      self.writeTimer = setTimeout(() => {
        if (title) {
          document.title = title;
        }
        _this.historyNavigate(url, title, routeState);
        self.writeTimer = undefined;
      }, delay ?? self.writeDelay);
    };

    const doc = this.document;

    historyObject.read = function () {
      const self = this as any;
      const url = self.parseURL({
        qsModule: qs,
        location: doc.location
      });
      if (url.Products) {
        return url;
      }
      return {};
    };

    return historyObject;
  }

  private historyNavigate(url: string, title: string, routeState: any) {
    const urlParts = url.split('/');
    urlParts.splice(0, 3);
    url = '/' + urlParts.join('/');

    const currentState: object = this.location.getState() as object ?? {};
    const state = {...currentState, ...routeState};
    window.history.replaceState && window.history.replaceState(state, title || '', url);
  }

  getSearchConfig(filters: AlgoliaFacetFilter[]|undefined): InstantSearchOptions {
    if (!filters) {
      throw new Error('filters must be defined')
    }
    if (this.config) {
      return this.config;
    }
    this.config = this.algoliaService.searchConfig(undefined, true);
    this.config.routing = {
      router: this.getHistory()
    };
    this.config.initialUiState = this.filterToUiState(filters, this.filter, this.config.indexName, this.algoliaService.sortByIndex);

    return this.config;
  }

  get helper(): AlgoliaSearchHelper | null {
    return this.instantSearch?.instantSearchInstance?.helper;
  }

  hitsChange({results}: ChangeEvent) {
    if (results) {
      this.hits.set(results.nbHits);
      this.hasLoadedHits.set(true);
    }
  }
}
