<div class="cart-preview">
  <div class="title font-serif">
    <p class="mb-0">Indkøbskurv</p>
    <span class="cart-amount">{{ cart.itemsTotalQty }} {{ cart.itemsTotalQty === 1 ? 'vare' : 'varer' }}</span>
  </div>
  <div class="overflow-auto">
    <app-cart-item
      (goToProductEvent)="showPreviewChange.emit(false)"
      *ngFor="let cartItem of cart.items; trackBy: trackBy"
      [cartItem]="cartItem"
      [minimizeHeight]="true"
      [smallCart]="true"
      [smallQtyInput]="isMobile"
    ></app-cart-item>
  </div>
  <app-free-shipping-incentive [cart]="cart"></app-free-shipping-incentive>
  <div class="cart-total">
    <p class="mb-0">Total</p>
    <p class="mb-0">{{cart.totalPrice | price | currency}} kr.</p>
  </div>
  <div class="cart-tax">
    <p class="mb-0">Moms udgør</p>
    <p class="mb-0">{{cart?.totalTax | price | currency}} kr.</p>
  </div>
  <app-superpay-buy-btn
    *ngIf="(cart?.items?.length|exists) && !cart.isVirtual"
   referer="cart-preview" class="auto mb-2"
  ></app-superpay-buy-btn>
  <button (click)="goToCheckout()" class="btn btn-primary w-100 cart-btn">GÅ TIL KASSEN</button>
</div>
<div (click)="showPreviewChange.emit(false)" class="backdrop"></div>
