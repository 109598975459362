import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {NgIf} from '@angular/common';
import {Rating} from '@core/interfaces/generated/graphql';
import {ExistsPipe} from '@core/pipes/exists.pipe';

@Component({
  selector: 'app-product-rating-points',
  templateUrl: './product-rating-points.component.html',
  styleUrls: ['./product-rating-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, ExistsPipe]
})
export class ProductRatingPointsComponent {

  rating = input.required<Rating>()

}
