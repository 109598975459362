import {Component, computed, HostBinding, input, signal} from '@angular/core';
import {NgIf, NgOptimizedImage} from '@angular/common';
import {WineProduct} from '@core/interfaces/generated/graphql';
import {CountdownLiveComponent} from '@layout/components/countdown-live/countdown-live.component';
import {ProductService} from '@core/services/product.service';


@Component({
  selector: 'app-product-top-cta',
  templateUrl: './product-top-cta.component.html',
  styleUrls: ['./product-top-cta.component.scss'],
  standalone: true,
  imports: [NgIf, NgOptimizedImage, CountdownLiveComponent]
})
export class ProductTopCtaComponent {

  product = input.required<WineProduct | undefined>();
  lastPrice = this.productService.getDefaultPriceSignal(this.product);
  showTopCta = signal(true);

  @HostBinding('class.hide') get hide() {
    return !this.showTopCta();
  }

  topCta = computed(() => this.product()?.topCta);
  iconSrc = computed(() => {
    const icon = this.topCta()?.icon;
    if (icon === 'CTA_WINE_BOTTLE') {
      return '/assets/images/wine-bottle-cta-icon.png';
    }
    if (icon === 'CTA_DISCOUNT_TAG') {
      return '/assets/images/price_tag.png';
    }
    return '';
  })

  constructor(
    protected productService: ProductService,
  ) {
  }

  toggleVisibility(show: boolean) {
    this.showTopCta.set(show);
  }

}
