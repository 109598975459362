import {Component, computed, forwardRef, Inject, input, Signal} from '@angular/core';
import {InstantsearchHierarchicalMenuComponent} from '../../instantsearch-hierarchical-menu.component';
import {HierarchicalMenuItem} from 'instantsearch.js/es/connectors/hierarchical-menu/connectHierarchicalMenu';
import {UrlNameService} from '@core/services/url-name.service';
import {RouterModule} from '@angular/router';
import {
  InstantsearchRefinementListComponent
} from '../../../../../instantsearch-refinement-list/instantsearch-refinement-list.component';
import {defaultSortBy} from '@core/instantsearch/components/instantsearch-base-refinement-list.component';
import {NgIf} from '@angular/common';
import {RouterContextDirective} from '@core/directives/router-context.directive';

@Component({
  selector: 'app-instantsearch-hierarchical-menu-last',
  templateUrl: './instantsearch-hierarchical-menu-last.component.html',
  styleUrls: ['./instantsearch-hierarchical-menu-last.component.scss'],
  standalone: true,
  imports: [RouterModule, InstantsearchRefinementListComponent, NgIf, RouterContextDirective]
})
export class InstantsearchHierarchicalMenuLastComponent {

  path = input<string | null>(null);

  sortBy = defaultSortBy;

  constructor(
    @Inject(forwardRef(() => InstantsearchHierarchicalMenuComponent))
    public instantsearchHierarchicalMenu: InstantsearchHierarchicalMenuComponent,
    public urlNameService: UrlNameService,
  ) {
  }

  get activeLvl0Item(): Signal<HierarchicalMenuItem | undefined> {
    return this.instantsearchHierarchicalMenu.activeLvl0Item;
  }

  get activeLvl1Item(): Signal<HierarchicalMenuItem> {
    return this.instantsearchHierarchicalMenu.activeLvl1Item;
  }

  get activeLvl2Item(): Signal<HierarchicalMenuItem> {
    return this.instantsearchHierarchicalMenu.activeLvl2Item;
  }

  get activeMenuItem() {
    return computed(() => this.activeLvl2Item().isRefined ? this.activeLvl2Item() : this.activeLvl1Item());
  }

  get transformValue() {
    return this.instantsearchHierarchicalMenu.transformValue;
  }

  join(parts: string[], separator: string) {
    return parts.filter(part => part).join(separator);
  }

  getUrlName(nonFriendlyUrl: string) {
    return this.urlNameService.getFriendlyUrl(nonFriendlyUrl) ?? '';
  }

  getItemLabel(item: any) {
    return this.instantsearchHierarchicalMenu.getItemLabel(item);
  }
  getItemSearchParams(item: any) {
    return this.instantsearchHierarchicalMenu.getItemSearchParams(item);
  }
  getItemPathname(item: any) {
    return this.instantsearchHierarchicalMenu.getItemPathname(item);
  }

  getAdditionalContext(item: any, eventSourceName: string) {
    const separator = this.instantsearchHierarchicalMenu.separator;
    const menu_navigation_path = this.path() + separator + item.label;
    return {
      menu_navigation_path,
      eventSourceName
    }
  }

  getRefinementItemParams(itemLabel: string, itemParamName: string) {
    const activeParams = this.instantsearchHierarchicalMenu.getItemSearchParams(this.activeMenuItem());
    activeParams['Products[refinementList][' + itemParamName +'][0]'] = itemLabel;
    return activeParams;
  }
}
