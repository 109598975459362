import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  EventEmitter,
  input,
  Output,
  signal,
  Signal,
  TrackByFunction,
  ViewChild
} from '@angular/core';
import {
  NguCarouselConfig,
  NguCarouselDefDirective,
  NguCarouselNextDirective,
  NguCarouselPrevDirective,
  NguTileComponent
} from '@ngu/carousel';
import {Image, WineProduct} from '@core/interfaces/generated/graphql';
import {AsyncPipe, NgIf, NgOptimizedImage} from '@angular/common';
import {StoryblokService} from '@core/services/storyblok.service';
import {ProductImage} from '@modules/page/components/product/components/product-image/product-images.component';
import {CarouselComponent} from '@layout/components/carousel/carousel.component';

@Component({
  selector: 'app-product-image-thumbnail-carousel',
  templateUrl: './product-image-thumbnail-carousel.component.html',
  styleUrls: ['./product-image-thumbnail-carousel.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgOptimizedImage, AsyncPipe, NguTileComponent, NguCarouselDefDirective, NguCarouselPrevDirective, NguCarouselNextDirective, CarouselComponent],
  'host': {ngSkipHydration: 'true'},
})
export class ProductImageThumbnailCarouselComponent {

  @Output() activeImageIdChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('carousel') carousel: CarouselComponent<WineProduct['images']>;

  public carouselTile: NguCarouselConfig = {
    grid: {
      xs: 3, sm: 3, md: 3, lg: 3, all: 0
    },
    point: {
      visible: true
    },
    load: 3,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  public showNavigationButtons: Signal<boolean> = computed(() => (this.images()?.length ?? 0) > 3)

  uniqueToken = signal<string | null>(null);

  images = input<ProductImage[] | null>(null);
  activeImageId = input<string | null>(null);

  constructor(
    public storyblokService: StoryblokService
  ) {
    effect(() => {
      const images = this.images();
      if (!images) {
        return;
      }
      this.uniqueToken.set(images?.map(d => d?.id).join('-'));
      const currentActiveImageId = this.activeImageId();
      const activeImageIndex = this.images()?.findIndex(image => image?.id === currentActiveImageId);
      if (activeImageIndex && activeImageIndex !== -1) {
        this.carousel.moveTo(activeImageIndex);
      }
    }, {allowSignalWrites: true});
  }


  onClick(item: Image): void {
    if (!item) {
      return;
    }
    this.activeImageIdChange.emit(item?.id ?? '');
  }

  trackBy(): TrackByFunction<WineProduct> {
    return (index, item) => {
      return item?.id;
    };
  }
}
