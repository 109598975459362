import {Routes} from '@angular/router';
import {GroupBuyPageComponent} from '@modules/page/components/group-buy-page/group-buy-page.component';
import {SearchPageAlgoliaComponent} from '@modules/page/components/search-page-algolia/search-page-algolia.component';
import {userGuard} from '@core/guards/user.guard';
import {PageComponent} from '@modules/page/page.component';

export const routes: Routes = [
  {
    path: 'gruppekoeb',
    component: GroupBuyPageComponent,
    title: 'Gruppekøb'
  },
  {
    path: 'searchpage',
    component: SearchPageAlgoliaComponent,
    title: 'Search'
  },
  {
    path: 'checkout',
    loadChildren: () => import('@modules/checkout/checkout.routing')
  },
  {
    path: 'user',
    canActivate: [userGuard],
    loadChildren: () => import('@modules/user-universe/user-universe.routing')
  },
  {
    path: '**',
    component: PageComponent,
    title: 'Loading...'
  },
];
