import {
  AfterViewInit,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Inject,
  Input,
  NgZone,
  OnDestroy,
  Output,
  PLATFORM_ID,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
  WritableSignal
} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {connectInfiniteHits} from 'instantsearch.js/es/connectors';
import {AlgoliaService} from '@core/services/algolia.service';
import {ProductService} from '@core/services/product.service';
import {WineProduct} from '@core/interfaces/generated/graphql';
import {debounceTime, filter, tap} from 'rxjs/operators';
import {NavigationStart, Router} from '@angular/router';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {RoutingService} from '@core/services/routing.service';
import {ProductWidgetComponent} from '@layout/components/product-widget/product-widget.component';
import {LoadingIconComponent} from '@layout/components/loading-icon/loading-icon.component';
import {
  InfiniteHitsConnectorParams,
  InfiniteHitsWidgetDescription
} from 'instantsearch.js/es/connectors/infinite-hits/connectInfiniteHits';
import {SendEventForHits} from 'instantsearch.js/es/lib/utils';
import {AlgoliaWineProduct} from '@core/types/algolia-wine-product';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {TypedBaseWidgetComponent} from '@core/instantsearch/types-based-widget';
import {ScrollPosService} from '@core/services/scroll-pos.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-search-page-results',
  templateUrl: './search-page-results.component.html',
  styleUrls: ['./search-page-results.component.scss'],
  standalone: true,
  imports: [ProductWidgetComponent, LoadingIconComponent]
})
export class SearchPageResultsComponent extends TypedBaseWidgetComponent<InfiniteHitsWidgetDescription<AlgoliaWineProduct>, InfiniteHitsConnectorParams<AlgoliaWineProduct>> implements AfterViewInit, OnDestroy {
  navSub: Subscription | undefined;
  scrollSub: Subscription | undefined;

  rendering = signal(true);
  renderingPage = signal<number>(0);
  renderingMore = computed(() => this.renderingPage() !== this.state()?.results?.page);

  @ViewChild('bottomElement', {static: false}) bottomElement: ElementRef<HTMLDivElement>;
  @ViewChild('productList', {static: false}) productList: ElementRef<HTMLDivElement>;
  @ViewChildren('productWidget') productWidget: QueryList<ProductWidgetComponent>;

  @Input() sortBy: (a: any, b: any) => number;
  @Input() hideProductDefaultCta: boolean = false;

  @Output() hitsChange: EventEmitter<AlgoliaWineProduct[]> = new EventEmitter<AlgoliaWineProduct[]>();

  hits = computed(() => {
    return this.formatHits(this.state()?.hits)
  });
  ready = computed(() => this.state()?.isFirstPage !== undefined);

  private readonly destroy: DestroyRef = inject(DestroyRef);
  public override state: WritableSignal<{
    hits: AlgoliaWineProduct[];
    isLastPage: boolean;
    isFirstPage: boolean;
    showMore: () => void;
    showPrevious: () => void;
    results: any;
    sendEvent: SendEventForHits;
    bindEvent: () => string;
    currentPageHits: AlgoliaWineProduct[];
  } | undefined> = signal(undefined);

  constructor(
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchInstance: InstantSearchComponent,
    private algoliaService: AlgoliaService,
    private productService: ProductService,
    private router: Router,
    private routingService: RoutingService,
    @Inject(PLATFORM_ID) private platformId: object,
    protected ngZone: NgZone,
    protected scrollPosService: ScrollPosService
  ) {
    super('InfiniteHits');

    this.createWidget(connectInfiniteHits as any, {
      cache: this.getCache()
    });

    this.navSub = this.router.events.pipe(filter(type => type instanceof NavigationStart)).subscribe(data => {
      this.scrollPosService.saveScroll();
    });

    effect(() => {
      this.hitsChange.emit(this.formatHits(this.state()?.hits));
    });

    if (typeof window !== 'undefined') {
      this.ngZone.runOutsideAngular(() => {
        this.scrollSub = fromEvent(window, 'scroll').pipe(
          debounceTime(100),
          tap(d => this.scrollEvent())
        ).subscribe()
      })
    }

  }
  scrollEvent() {
    if (!this.bottomElement) {
      return;
    }
    const bottomElement = this.bottomElement.nativeElement;

    if (isPlatformBrowser(this.platformId) && this.elementInView(bottomElement)) {
      this.triggerShowMore();
    }
  }

  ngAfterViewInit() {
    this.productWidget.changes.pipe(
      takeUntilDestroyed(this.destroy)
    ).subscribe(() => {
      if (this.rendering()) {
        this.rendering.set(false);
        setTimeout(() => {
          this.scrollPosService.restoreScrollPosition(true);
        });
      }
    });
  }

  protected formatHits(hitsRaw: any[]|undefined): AlgoliaWineProduct[] {
    let hits = this.productService.filterProducts(hitsRaw || []) as AlgoliaWineProduct[];
    hits = hits?.map(hit => this.algoliaService.hitToWineProduct(hit as any));
    if (this.sortBy) {
      hits.sort((a, b) => this.sortBy(a, b));
    }
    return hits;
  }

  elementInView(element: HTMLElement): boolean {
    const position = element.getBoundingClientRect();
    if (position.height === 0 && position.width === 0) {
      return false;
    }

    const triggerBeforeElementInView = 300;

    return (
      position.top >= 0 &&
      position.bottom <= (window.innerHeight || document.documentElement.clientHeight) + triggerBeforeElementInView
    );
  }

  getCache() {
    return {
      read: ({state}: any) => {
        const key = JSON.stringify(this.getStateWithoutPage(state));
        return this.algoliaService.hitsCache[key];
      },
      write: ({state, hits}: any) => {
        const key = JSON.stringify(this.getStateWithoutPage(state));
        this.algoliaService.hitsCache[key] = hits;
      }
    };
  }

  protected getStateWithoutPage(state: any) {
    const {page, ...rest} = state || {};
    return rest;
  }

  get isInStoryblokEditor() {
    return this.routingService.getQueryParams()?.['_storyblok_release'] !== undefined;
  }


  get page() {
    return Math.ceil(this.hits.length / this.batchSize);
  }

  triggerShowMore() {
    if (this.renderingMore() || this.state()?.isLastPage || isPlatformServer(this.platformId) || this.isInStoryblokEditor) {
      return;
    }
    this.renderingPage.set(this.state()?.results.page + 1);
    this.state()?.showMore();
  }

  override ngOnDestroy() {
    this.navSub?.unsubscribe();
    this.scrollSub?.unsubscribe();
  }

  get helper(): any {
    return this.instantSearchInstance?.instantSearchInstance?.helper;
  }

  get batchSize() {
    return this.helper?.state?.hitsPerPage;
  }

  trackByFn(index: any, item: WineProduct) {
    return item.id;
  }

}
