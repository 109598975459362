import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {RoutingService} from './routing.service';
import {CookieService} from './cookie.service';
import {environment} from '../../../environments/environment';
import {isPlatformServer} from '@angular/common';
import {captureException} from '@sentry/angular-ivy';

@Injectable({providedIn: 'root'})
export class SuperpayService {

  protected readonly superpayUidCookieName = 'superpayUID';

  constructor(
    private router: RoutingService,
    protected cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
  }

  public initConfig() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    (window as any).superpayConfig = {
      store: environment.superpay.domain
    }
  }

  public getSuperpayCartId(): string|null {
    return this.router.getQueryParams<{ cartid: string }>(true)?.cartid || null
  }

  public setSuperpayUUID(superpayUserId: string|undefined, retries = 0) {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    if (!superpayUserId) {
      return;
    }
    const superpaySetCookie = (window as any)?.superpaySetCookie;

    if (retries > 300) {
      captureException(new Error('superpaySetCookie is not defined after 300 retries.'));
      return;
    }

    // If superpaySetCookie is not defined, wait for it to be defined
    if (typeof superpaySetCookie === 'undefined') {
      // If superpaySetCookie is not defined, wait for it to be defined
      setTimeout(() => {
        this.setSuperpayUUID(superpayUserId, retries + 1);
      }, 200);
      return;
    }
    superpaySetCookie(superpayUserId);
  }

  public removeSuperpayUserCookie() {
    this.cookieService.delete(this.superpayUidCookieName)
  }

}
