import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  input,
  IterableDiffers,
  NgIterable,
  NgZone,
  PLATFORM_ID,
  Renderer2
} from '@angular/core';
import {NguCarousel, NguCarouselOutlet} from '@ngu/carousel';
import {NguCarouselHammerManager} from '@layout/components/carousel/carousel-hammer-manager';
import {NguWindowScrollListener} from '@layout/components/carousel/carousel-window-scroll-listener';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NguCarouselHammerManager],
  imports: [NguCarouselOutlet],
  standalone: true,
  styleUrl: './carousel.component.css'
})
export class CarouselComponent<T, U extends NgIterable<T> = NgIterable<T>> extends NguCarousel<T, U> {

  uniqueToken = input.required<string>();

  constructor(
    _host: ElementRef<HTMLElement>,
    _renderer: Renderer2,
    _differs: IterableDiffers,
    @Inject(PLATFORM_ID) protected platformId: string,
    _cdr: ChangeDetectorRef,
    _ngZone: NgZone,
    _nguWindowScrollListener: NguWindowScrollListener,
    _nguCarouselHammerManager: NguCarouselHammerManager
  ) {
    super(
      _host,
      _renderer,
      _differs,
      isPlatformBrowser(platformId),
      _cdr,
      _ngZone,
      _nguWindowScrollListener as any,
      _nguCarouselHammerManager as any,
    );
    (this as any)._generateID = () => {
      return this.uniqueToken();
    }
  }
}
