import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgIf, NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-sad-wineglass-icon',
  templateUrl: './sad-wineglass-icon.component.html',
  styleUrls: ['./sad-wineglass-icon.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgOptimizedImage
  ]
})
export class SadWineglassIconComponent {

  @Input() text: string;
  @Input() buttonText?: string;
  @Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

}
