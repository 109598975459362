@if (ready()) {
  <div #productList class="row">
    @for (item of hits(); track trackByFn; let i = $index) {
      <article [product]="item"
               [queryID]="item.__queryID"
               [sendEvent]="state()?.sendEvent"
               [hideProductDefaultCta]="hideProductDefaultCta"
               [index]="i"
               app-product-widget
               #productWidget
               eventSourceName="algolia-list-page"
               class="col-12 col-sm-6 col-lg-4">
      </article>
    }
  </div>

  <div #bottomElement></div>

  @if (!state()?.isLastPage) {
    <app-loading-icon></app-loading-icon>
  }
}
