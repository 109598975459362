import {Inject, Injectable, PLATFORM_ID, Signal, signal, WritableSignal} from '@angular/core';
import {CustomerProfile, Maybe, Mutation, Query, SessionResponse} from '../interfaces/generated/graphql';
import {catchError, map, take, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {CartService} from './cart.service';
import {isPlatformBrowser} from '@angular/common';
import {SuperpayService} from './superpay.service';
import {ApolloService} from './apollo.service';
import {NetworkStatus} from '@apollo/client/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GroupVoucherService} from './groupVoucher.service';
import {CookieService} from './cookie.service';
import {UnsubscribeService} from './unsubscribe.service';
import {UserInitService} from './user-init.service';
import {SessionService} from './session.service';
import {setTags, setUser} from '@sentry/angular-ivy';
import {RuntimeIdService} from '@core/services/runtime-id.service';
import {SplitTestService} from '@core/services/split-test.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private currentUserSubject: BehaviorSubject<CustomerProfile | null> = new BehaviorSubject<CustomerProfile | null>(null);

  protected isAdminSignal: WritableSignal<boolean> = signal(false);

  userSub: Subscription;

  constructor(
    private apollo: ApolloService,
    private cartService: CartService,
    protected superpayService: SuperpayService,
    @Inject(PLATFORM_ID) private platformId: object,
    protected http: HttpClient,
    protected modalService: NgbModal,
    protected groupVoucherService: GroupVoucherService,
    private cookieService: CookieService,
    protected unsubscribeService: UnsubscribeService,
    protected userInitService: UserInitService,
    private sessionService: SessionService,
    protected runtimeIdService: RuntimeIdService,
    protected splitTestService: SplitTestService,
  ) {
    this.createUserSub();

    if (isPlatformBrowser(this.platformId)) {
      // For easy debugging
      (window as any).setUserAdmin = (isAdmin: boolean) => {
        this.isAdminSignal.set(isAdmin);
      }
    }
  }

  isAdmin(): Signal<boolean> {
    return this.isAdminSignal;
  }

  protected hasAdminCookie() {
    return this.cookieService.get('supervin_was_admin');
  }

  triggerIsAdminCheck() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (!this.hasAdminCookie()) {
      return;
    }
    this.http.get<string | {
      loggedIn: boolean
    }>(location.protocol + '//' + location.host + '/supervin_admin/efiware/check/isadmin', {withCredentials: true})
      .pipe(
        catchError(err => {
          if (!(err.status > 0)) {
            console.error(err);
          }
          return of({loggedIn: false});
        }),
        take(1)
      )
      .subscribe(res => {
        let obj = res;
        if (typeof obj === 'string') {
          if (obj.indexOf('{') !== 0) {
            return;
          }
          obj = JSON.parse(obj);
        }

        const isLoggedIn = (obj as any).loggedIn;

        this.isAdminSignal.set(!!isLoggedIn);
      })
  }

  getUser(): Observable<CustomerProfile | null> {
    return this.currentUserSubject.asObservable();
  }

  currentUser(): CustomerProfile | null {
    return this.currentUserSubject.getValue();
  }

  logout() {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'signOutMutation'
    }).then(res => {
      const session = res.data?.signOutV2;
      this.removeUserToken();
      this.updateUser(session);
    });
  }

  login(username: string, password: string): Promise<CustomerProfile | undefined> {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'authenticateMutation',
      variables: {
        email: username,
        password
      }
    }).then(res => {
      const authResponse = res.data?.authenticateV2;
      this.updateOrSetUserToken(authResponse?.authToken);
      this.updateUser(authResponse?.sessionResponse);
      return authResponse?.sessionResponse?.customerProfile || undefined;
    }).catch(err => {
      if (err.isApolloError) {
        const resetPasswordError = err.graphQLErrors.find((e: any) => {
          return e.debugMessage === 'CUSTOMER_EXISTS_WITHOUT_PASSWORD';
        });
        if (resetPasswordError) {
          this.forgottenPasswordModal(username, true, true);
          return undefined;
        }
      }
      throw err;
    })
  }

  forgottenPasswordModal(email: Maybe<string> | undefined = undefined, showNewPasswordAlert = false, existingUserWithoutPassword = false, token: string | undefined = undefined) {
    import('../../content/layout/components/forgotten-password-modal/forgotten-password-modal.component').then(({ForgottenPasswordModalComponent}) => {
      this.modalService.dismissAll();
      const modalReference = this.modalService.open(ForgottenPasswordModalComponent);
      if (email) {
        modalReference.componentInstance.email = email;
        modalReference.componentInstance.showNewPasswordAlert = showNewPasswordAlert;
        modalReference.componentInstance.existingUserWithoutPassword = existingUserWithoutPassword;
        modalReference.componentInstance.rpToken = token;
      }
    });
  }

  public changePassword(newPassword: string, email?: string, resetToken?: string) {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'changePasswordMutation',
      variables: {
        newPassword: newPassword,
        email: email,
        resetToken: resetToken,
      }
    });
  }

  protected updateUser(user: SessionResponse | null | undefined) {
    const parsed = user?.customerProfile || null;
    this.currentUserSubject.next(parsed?.id as any as number > 0 ? parsed : null);
    this.cartService.updateCart(user?.cart);
    this.groupVoucherService.setGroupVoucher(user?.groupVoucher || null);
    this.sessionService.setSession(user?.userSession);
  }

  protected isSuccessPage() {
    const pathname = window.location.pathname;
    return pathname.indexOf('/checkout') !== -1 && pathname.indexOf('/success') !== -1;
  }

  get userToken() {
    return this.cookieService.get('userToken');
  }

  protected setUserToken(userToken: string) {
    const expiresDate = this.cookieService.getExpiresDate(365);
    this.cookieService.set('userToken', userToken, expiresDate, '/');
  }

  updateSession(sessionResponse: SessionResponse | null | undefined) {

    setUser({
      id: sessionResponse?.id ?? '',
    });

    setTags({
      sessionId: sessionResponse?.userSession?.sessionId ?? '',
      cartId: sessionResponse?.cart?.cartId,
      loggedIn: !!sessionResponse?.customerProfile,
    });

    this.updateUser(sessionResponse);
  }

  private createUserSub() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if (this.isSuccessPage()) {
      this.cartService.dropCart();
    }

    const superpayCartId = this.superpayService.getSuperpayCartId();
    if (superpayCartId) {
      this.cartService.setCartId(superpayCartId);
    }

    this.userSub = this.apollo.watchQuery<Query>({
      queryName: 'getSessionQuery',
      variables: {
        sessionSplitTests: JSON.stringify(this.splitTestService.splitTestsVersions)
      }
    }).pipe(
      takeUntil(this.unsubscribeService.whileApplicationAlive()),
      map(res => ({user: res.data.getSession || undefined, isLoaded: res.networkStatus === NetworkStatus.ready})),
      tap(({user, isLoaded}: { isLoaded: boolean, user: SessionResponse | undefined }) => {
        this.updateSession(user);
        this.splitTestService.setSplitTestCases(user?.userSession?.testCases);
        if (isLoaded) {
          this.userInitService.setInitialized();
        }
        if (user?.cart?.id) {
          this.cookieService.delete('cartId');
        }
      }),
    ).subscribe();
  }

  protected removeUserToken() {
    this.cookieService.delete('userToken');
  }

  protected updateOrSetUserToken(userToken: string | null | undefined) {
    if (userToken) {
      this.setUserToken(userToken);
    } else {
      this.removeUserToken();
    }
  }

  public isResetPasswordTokenValid(email: string, resetToken: string): Promise<boolean> {
    return this.apollo.mutatePromise<Query>({
      queryName: 'isRpTokenValidQuery',
      variables: {
        email,
        resetToken
      }
    }).then(res => {
      return res.data?.isRpTokenValid || false;
    });
  }

  public getCustomerUUIDByEmail(email: string) {
    return this.apollo.queryPromise({
      queryName: 'getCustomerUUIDByEmailQuery',
      variables: {
        email: email
      }
    }).then((res: any) => res.data.getCustomerUuidByEmail);
  }

}
