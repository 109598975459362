import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {Food, Maybe, Rating, WineProduct} from '@core/interfaces/generated/graphql';
import {Observable} from 'rxjs';
import {StoryblokDirective} from '@core/directives/storyblok.directive';

import {ProductCarouselComponent} from '@layout/components/product-carousel/product-carousel.component';
import {AsyncPipe, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {StoryblokBaseComponent} from '../storyblok-base.component';
import {ProductService} from '@core/services/product.service';
import {ProductRatingComponent} from '@layout/components/product-rating/product-rating.component';
import {ExistsPipe} from '@core/pipes/exists.pipe';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {StoryblokService} from '@core/services/storyblok.service';
import {RoutingService} from '@core/services/routing.service';
import {
  ProductRatingPointsComponent
} from '@layout/components/product-rating/product-rating-points/product-rating-points.component';
import {StoryblokStylingService} from '@core/services/storyblok-styling.service';

@Component({
  selector: 'app-storyblok-product-card',
  templateUrl: './storyblok-product-card.component.html',
  styleUrls: ['./storyblok-product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoryblokDirective, NgForOf, AsyncPipe, ProductCarouselComponent, NgIf, NgOptimizedImage, ProductRatingComponent, ExistsPipe, FontAwesomeModule, ProductRatingPointsComponent]
})
export class StoryblokProductCardComponent extends StoryblokBaseComponent implements OnInit {
  @Input() vismaId: string;
  @Input() _editable: any;
  @Input() general_styling: any;
  @HostBinding('attr.id') hostElementId: any;
  @HostBinding('class') class: any;

  constructor(
    public productService: ProductService,
    private storyblokService: StoryblokService,
    private routingService: RoutingService,
    private storyblokStylingService: StoryblokStylingService,
  ) {
    super()
  }

  product$: Observable<Maybe<WineProduct> | undefined>;
  productStoryblok$: Observable<any>

  @Input()
  set elementId(value: any) {
    this.hostElementId = value;
  }

  ngOnInit() {
    this.product$ = this.productService.getProductByVismaId(this.vismaId);
    this.productStoryblok$ = this.storyblokService.getStoryObservable('product', this.routingService.getQueryParams());
    this.class = this.getClass();
  }

  ratings(product: WineProduct): Rating[] {
    const ratings = product?.meta?.ratings;
    if (!ratings) {
      return [];
    }
    return ratings.filter(d => !!d).slice(0, 3) as Rating[];
  }

  getFoods(product: WineProduct): Food[] {
    return product?.meta?.foods?.filter(d => !!d).slice(0, 5) as Food[];
  }

  getClass(): string[] {
    return this.storyblokStylingService.getClassList(this.general_styling, undefined, {'col-': 12});
  }
}
