import {Component, input} from '@angular/core';
import {ClickTrackingDirective} from '@core/directives/click-tracking.directive';

@Component({
  selector: 'app-trustpilot',
  templateUrl: './trustpilot.component.html',
  styleUrls: ['./trustpilot.component.scss'],
  standalone: true,
  imports: [
    ClickTrackingDirective
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {ngSkipHydration: 'true'}
})
export class TrustpilotComponent {

  webpage = input<string>('www.supervin.dk');
  businessunitId = input<string>('488898fc00006400050306c7')
  theme = input<string>('dark')
  styleHeightPx = input<number>(40)
  templateId = input<string>('5419b6ffb0d04a076446a9af')

  get URL(): string {
    return `https://dk.trustpilot.com/review/${this.webpage}`;
  }

}
