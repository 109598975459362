import {computed, Injectable, Signal} from '@angular/core';
import {
  Discount,
  GroupVoucherCode,
  GroupVoucherCodePrice,
  Maybe,
  PriceTablePricing,
  ProductBase,
  Query,
  WineProduct
} from '../interfaces/generated/graphql';
import {GroupVoucherService} from './groupVoucher.service';
import {clone} from 'lodash-es';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {RoutingService} from './routing.service';
import {ApolloService} from './apollo.service';
import {formatPrice} from '../pipes/price.pipe';
import {AlgoliaWineProduct} from '../types/algolia-wine-product';

export interface CustomDiscount extends Discount {
  strikeThrough?: boolean;
}

export interface Pricing extends PriceTablePricing {
  discount?: CustomDiscount;
  showVatText?: boolean;
  strikeThrough?: boolean;
  source?: string;
}

export interface ProductResult {
  product?: WineProduct;
  error?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private routingService: RoutingService,
    private voucherService: GroupVoucherService,
    private apollo: ApolloService,
  ) {
  }

  private getPrice(product: ProductBase, qty?: number): Pricing {
    const prices = this.getFormattedProductPrices(product);
    const compareAtQty = qty ? qty : product.defaultQuantity || 0;
    const price = prices.find(price => {
      return (price.maxQty && price.maxQty !== -1 ? price.maxQty : 1000) >= compareAtQty;
    });
    return price ?? prices[prices.length - 1];
  }

  getDefaultPriceFromProduct(product: ProductBase, qty?: number): number {
    const price = this.getPrice(product, qty);

    return formatPrice(price.amountIncVat);
  }

  getDiscountPriceFromProduct(product: ProductBase, qty?: number): number {
    const price = this.getPrice(product, qty);

    return formatPrice(price.discount?.amountIncVat || price.amountIncVat);
  }

  getFormattedProductPrices(product: ProductBase, voucherCode?: GroupVoucherCode | null): Pricing[] {
    if (!product?.priceTable?.pricings) {
      return [];
    }
    let prices = product.priceTable.pricings.map(d => clone(d)).sort((a: any, b: any) => {
      return a.minQty - b.minQty;
    }) as Pricing[];

    const voucher = voucherCode ?? this.voucherService.currentGroupVoucher();
    const voucherPrice = voucher?.prices?.find(price => price?.productId === (product as WineProduct)?.id.toString());

    prices = prices.map(price => {
      if (price.discount && price.minQty === 1) {
        price.strikeThrough = true;
      }
      return price;
    });

    if (product.id && voucherPrice) {
      const defaultPrice = prices[prices.length - 1];
      prices.push(this.createGroupVoucherDiscount(voucher as GroupVoucherCode, clone(defaultPrice), product, voucherPrice));

      defaultPrice.strikeThrough = true;
      if (defaultPrice.discount) {
        defaultPrice.discount.strikeThrough = true;
      }
    }

    return prices;
  }

  getDefaultPriceAsync(product: ProductBase): Observable<Pricing> {
    return this.getFormattedProductPricesAsync(product).pipe(
      map(data => this.getDefaultPrice(product, data.prices))
    );
  }

  getDefaultPriceSignal(productS: Signal<ProductBase | undefined>) {
    return computed(() => {
      const product = productS();
      if (!product) {
        return;
      }
      const prices = this.getFormattedProductPricesSignal(productS)();
      return this.getDefaultPrice(product, prices)
    })
  }

  getFormattedProductPricesSignal(productS: Signal<ProductBase|undefined>) {
    return computed(() => {
      const product = productS();
      if (!product) {
        return [];
      }
      const groupVoucher = this.voucherService.groupVoucherSignal();
      return this.getFormattedProductPrices(product, groupVoucher.groupVoucher)
    })
  }

  getFormattedProductPricesAsync(product: ProductBase | undefined): Observable<{
    prices: Pricing[],
    groupVoucher: GroupVoucherCode | null
  }> {
    if (!product) {
      return of({prices: [], groupVoucher: null})
    }
    return this.voucherService.getGroupVoucher().pipe(
      map(v => {
        return {
          prices: this.getFormattedProductPrices(product, v),
          groupVoucher: v
        }
      })
    );
  }

  getProducts(productIds: string[]): Observable<ProductResult[] | undefined> {
    return this.apollo.watchQuery<Query>({
      queryName: 'getProductsByVismaIds',
      variables: {
        ids: productIds
      }
    }).pipe(
      map((data: any) => {
        const filteredProducts = this.filterProducts(data?.data?.getProductsByVismaIds);
        return filteredProducts?.map((val, i) => {
          const errorMsg = !val?.id ? `Product with id #${productIds[i]} not found.` : undefined;
          return {product: val, error: errorMsg};
        });
      }),
    );
  }

  getProductByVismaId(vismaId: string): Observable<Maybe<WineProduct> | undefined> {
    return this.apollo.watchQuery<Query>({
      queryName: 'getProductByVismaId',
      variables: {
        id: vismaId
      }
    }).pipe(
      map((data) => {
        return data.data.getProductByVismaId;
      }),
    );
  }

  getProductAdminDataByVismaId(vismaId: string) {
    return this.apollo.watchQuery<Query>({
      client: 'post-query',
      queryName: 'getProductStockQuery',
      variables: {
        id: vismaId
      }
    }).pipe(
      map((data) => data.data.getProductByVismaId)
    );
  }

  protected isOnWhitelistedPage(product: WineProduct): boolean {
    if (!product?.pageWhitelist || product?.pageWhitelist?.length === 0) {
      return true;
    }

    const url = `/${this.routingService.getURL()}`;
    return product?.pageWhitelist?.includes(url);
  }

  filterProducts(products: WineProduct[] | AlgoliaWineProduct[] | null) {
    if (!products) {
      return products;
    }

    return products.filter(product => this.isOnWhitelistedPage(product));
  }

  private getDefaultPrice(product: ProductBase, prices?: Pricing[]): Pricing {
    prices = prices ?? this.getFormattedProductPrices(product);
    return prices.find(price => price.minQty === product.defaultQuantity && !price.strikeThrough && !price.discount?.strikeThrough) ?? prices[prices.length - 1];
  }

  getItemIcon(item: string | null, storyblokProduct: any, pdfIcons = false) {
    if (!storyblokProduct || !item) {
      return;
    }

    const icons = storyblokProduct?.fitsWellIcons;
    const icon = icons.find((data: {
      machine_name: string;
    }) => data.machine_name.toLowerCase() === item.toLowerCase());
    if (pdfIcons) {
      return icon?.image_pdf?.filename;
    }
    return icon?.image?.filename;
  }

  private createGroupVoucherDiscount(voucher: GroupVoucherCode, defaultPrice: Pricing, product: ProductBase, voucherPrice: GroupVoucherCodePrice): Pricing {
    const groupVoucherPrice = parseInt(voucherPrice.price, 10);
    return {
      ...defaultPrice,
      minQty: product.defaultQuantity,
      amount: groupVoucherPrice,
      amountIncVat: groupVoucherPrice,
      source: voucher?.code,
      showVatText: voucher?.showVatText || undefined,
      strikeThrough: false,
      discount: {
        amountIncVat: groupVoucherPrice,
        to: null,
      }
    };
  }
}
