import {Component, EventEmitter, Output} from '@angular/core';
import {UserService} from '../../../../../../core/services/user.service';
import {QuicksearchComponent} from '../quicksearch/quicksearch.component';
import {Router, RouterModule} from '@angular/router';

import {HeaderMiddleVoucherComponent} from './components/header-middle-voucher/header-middle-voucher.component';
import {CartSmallComponent} from '../cart-small/cart-small.component';
import {AsyncPipe, NgIf, NgOptimizedImage} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StoryblokService} from '../../../../../../core/services/storyblok.service';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {TrustpilotComponent} from '@layout/components/trustpilot/trustpilot.component';
import {RouterContextDirective} from '@core/directives/router-context.directive';
import {TrackEventService} from '@core/services/track-event.service';


@Component({
  selector: 'app-header-middle',
  templateUrl: './header-middle.component.html',
  styleUrls: ['./header-middle.component.scss'],
  standalone: true,
  imports: [QuicksearchComponent, RouterModule, NgIf, AsyncPipe, HeaderMiddleVoucherComponent, CartSmallComponent, NgOptimizedImage, FontAwesomeModule, TrustpilotComponent, RouterContextDirective]
})
export class HeaderMiddleComponent {
  currentUserSubject;

  @Output() toggleMenu: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private modalService: NgbModal,
    private userService: UserService,
    private router: Router,
    public storyblokService: StoryblokService,
    private trackEventService: TrackEventService
  ) {
    this.currentUserSubject = userService.getUser();

  }

  goToUserUniverse() {
    return this.router.navigateByUrl('/user/orders');
  }

  open() {
    if (this.userService.currentUser()?.id) {
      this.goToUserUniverse();
      return;
    }
    this.trackEventService.trackClickEvent('login_modal_open');
    import('../../../login-modal/login-modal.component').then(comp => {
      this.modalService.open(comp.LoginModalComponent);
    })
  }

  openWelcomeModal() {
    this.trackEventService.trackClickEvent('welcome_modal_open');
    import('../../../account-access-modal/welcome-modal.component').then(comp => {
      this.modalService.open(comp.WelcomeModalComponent);
    })
  }

  protected readonly faBars = faBars;
}
