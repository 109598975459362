import {Inject, Injectable, makeStateKey, OnDestroy, PLATFORM_ID, TransferState} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {LinkService} from './link.service';
import {Head, HeadLink, HeadMeta, Maybe} from '../interfaces/generated/graphql';
import {Subscription} from 'rxjs';
import {environment} from '@env/environment';
import {PageService} from './page.service';
import {RoutingService} from './routing.service';
import {isPlatformServer} from '@angular/common';
import {ScriptService} from '@core/services/script.service';

@Injectable({
  providedIn: 'root'
})
export class HeadService implements OnDestroy {

  private tagElements: (HeadMeta | HeadLink)[] = [];
  private tagElementsStateKey = 'headTagElements';

  private pageSub: Subscription;
  public isAutoHeadersEnabled = true;

  constructor(
    private meta: Meta,
    private link: LinkService,
    private title: Title,
    private transferState: TransferState,
    private pageService: PageService,
    private routingService: RoutingService,
    private scriptService: ScriptService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
  }

  public initHeadService() {
    if (this.pageSub) {
      return;
    }
    this.tagElements = this.transferState.get(makeStateKey(this.tagElementsStateKey), []);
    this.pageSub = this.pageService.getPageAsync().subscribe(page => {
      this.updateTitleAndTags(page?.page.title || 'Supervin', page?.page.head);
      this.updateStructuredDataScheme(page?.page.head?.structuredDataScheme);
    });
  }

  ngOnDestroy() {
    this.pageSub.unsubscribe();
  }

  findElementsByHeadTag(headTag: HeadMeta | HeadLink): HTMLElement[] {
    if ((headTag as HeadMeta).name) {
      return this.findHTMLElementsByHeadMeta(headTag as HeadMeta);
    } else {
      return this.findHTMLElementsByHeadLink(headTag as HeadLink);
    }
  }

  resetTags() {
    this.tagElements = [];
    this.transferState.set<any>(makeStateKey(this.tagElementsStateKey), this.tagElements);
  }

  findHTMLElementsByHeadLink(headLink: HeadLink): HTMLLinkElement[] {
    if (!headLink.rel || isPlatformServer(this.platformId)) {
      return [];
    }
    return Array.from(document.querySelectorAll(`head link[rel="${headLink.rel}"]`));
  }

  findHTMLElementsByHeadMeta(headLink: HeadMeta): HTMLMetaElement[] {
    let elements: any[] = [];
    if (headLink?.name) {
      elements = Array.from(document.querySelectorAll(`head meta[name="${headLink.name}"]`))
    } else if (headLink?.property) {
      elements = Array.from(document.querySelectorAll(`head meta[property="${headLink.property}"]`))
    }
    return elements;
  }

  removeElementsByHeadTag(headTag: HeadMeta | HeadLink) {
    if ((headTag as HeadMeta)?.name) {
      this.meta.removeTag(`name='${(headTag as HeadMeta)?.name}'`)
    }
    if ((headTag as HeadMeta)?.property) {
      this.meta.removeTag(`property='${(headTag as HeadMeta)?.property}'`)
    }
    if ((headTag as HeadLink)?.rel) {
      const elements = this.findElementsByHeadTag(headTag);
      elements?.forEach(e => e.remove());
    }
  }

  protected addTag(tag: HeadLink | HeadMeta) {
    this.tagElements.push(tag);

    this.transferState.set<any>(makeStateKey(this.tagElementsStateKey), this.tagElements);
  }

  addMetaTag(meta: HeadMeta) {
    meta = {...meta};
    this.removeElementsByHeadTag(meta);
    this.meta.addTag(this.prepareTag(meta));
    this.addTag(meta);
  }

  addLinkTag(link: HeadLink) {
    link = {...link};
    if (link.href) {
      link.href = this.replaceHostname(link.href);
    }
    this.removeElementsByHeadTag(link);
    this.link.addTag(this.prepareTag(link));
    this.addTag(link);
  }

  updateTitleAndTags(title: string, head: Head | null = null) {
    this.tagElements.forEach(el => this.removeElementsByHeadTag(el));
    this.resetTags();

    this.title.setTitle(title || 'Supervin');

    if (head?.meta) {
      console.log('head.meta', head.meta);
      head.meta.forEach((meta: any) => {
        this.addMetaTag(meta);
      });
    }
    if (head?.link) {
      head.link.forEach((link: any) => {
        this.addLinkTag(link);
      });
    }

    this.addRobotsTag();
  }

  protected replaceHostname(url: string): string {
    const newHostname = environment.supervin.frontendHostname;
    try {
      const urlObj = new URL(url);
      urlObj.host = newHostname;
      return urlObj.href;
    } catch (error) {
      return url;
    }
  }

  protected prepareTag(data: any) {
    const keys = Object.keys(data);
    const newObj: { [index: string]: any } = {};

    for (const key of keys) {
      if (!data[key] || key === '__typename') {
        continue;
      }

      newObj[key] = data[key];
    }

    return newObj;
  }

  private addRobotsTag() {
    const host = this.routingService.hostname;
    if (environment.supervin.previewHostname === host) {
      this.addMetaTag({name: 'robots', content: 'noindex'});
    }
  }

  async updateStructuredDataScheme(structuredDataScheme: Maybe<string> | undefined): Promise<void> {
    this.scriptService.removeScriptById('structured-data-scheme', 'head');
    if (!structuredDataScheme) {
      return;
    }
    await this.scriptService.addScript({
      type: 'application/ld+json',
      async: false,
      text: structuredDataScheme,
      id: 'structured-data-scheme'
    }, false, 'head', true);
  }
}
