<div (click)="isPreviewVisible$.next(!isPreviewVisible$.value)" class="cart-small justify-content-end" [attr.data-rr-shared]="(cartSignal().cart?.itemsTotalQty || 0) <= 0">
  <div *ngIf="cartSignal().cart?.totalPrice|exists" class="cart-total">
    <span>{{ cartSignal().cart?.totalPrice | price | currency }}&nbsp;<span class="currency-c"> DKK </span></span>
  </div>
  <div class="cart-title">
    <i class="supervin-icon-cart"></i>
    <span class="num">{{ cartSignal().cart?.itemsTotalQty || '0' }}</span> <span class="cart-text hide-content-mobile">Kurv</span>
  </div>
</div>
<app-cart-small-preview
  (showPreviewChange)="isPreviewVisible$.next($event)"
  *ngIf="(isPreviewVisibleObservable|async) && cartSignal().cart"
  [cart]="cartSignal().cart"
></app-cart-small-preview>
