import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
  TrackByFunction
} from '@angular/core';
import {WineProduct} from '../../../../../core/interfaces/generated/graphql';
import {Observable} from 'rxjs';
import {StoryblokStylingService} from '../../../../../core/services/storyblok-styling.service';
import {ProductService} from '../../../../../core/services/product.service';
import {map, tap} from 'rxjs/operators';
import {ProductWidgetComponent} from '../../../../layout/components/product-widget/product-widget.component';
import {AsyncPipe, NgForOf} from '@angular/common';
import {StoryblokBaseComponent} from '../storyblok-base.component';


@Component({
  selector: 'app-storyblok-product-list',
  templateUrl: './storyblok-product-list.component.html',
  styleUrls: ['./storyblok-product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ProductWidgetComponent, NgForOf, AsyncPipe]
})

export class StoryblokProductListComponent extends StoryblokBaseComponent implements OnInit {
  @Input() visma_ids: string;

  @HostBinding('style') style: { [styleKey: string]: any };
  @HostBinding('class') class: string[];

  products$: Observable<WineProduct[]>;
  errors: string[];

  constructor(
    private storyblokStylingService: StoryblokStylingService,
    private productService: ProductService,
    private changeDetector: ChangeDetectorRef
  ) {
    super()
  }

  ngOnInit() {
    if (this.visma_ids) {
      const productObservable = this.visma_ids.split(',');
      this.products$ = this.productService.getProducts(productObservable).pipe(
        tap((data: any) => this.errors = data?.map((item: any) => item.error)),
        map((data: any) => data?.map((item: any) => item.product)?.sort((x: any, y: any) => (x.inStock === y.inStock) ? 0 : x.inStock ? -1 : 1)),
        tap(d => this.changeDetector.detectChanges())
      );
    }
    this.class = this.getClass() ?? ['d-flex', 'flex-wrap'];
  }

  getClass(): string[] | undefined {
    const classList = this.storyblokStylingService.getClassList(this.generalStyling);
    if (classList.findIndex((d) => d.indexOf('d-') === 0)) {
      return;
    } else {
      classList.push('d-flex', 'flex-wrap');
    }
    return classList;
  }

  tracker: TrackByFunction<WineProduct> = (index, item) => {
    return item.id;
  }
}
