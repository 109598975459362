import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {PageInfo} from '@core/interfaces/generated/graphql';
import {Observable, Subscription} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {RoutingService} from '@core/services/routing.service';
import {ActivatedRoute} from '@angular/router';
import {StoryblokService} from '@core/services/storyblok.service';
import {PageInfoService} from '@core/services/page-info.service';

import {DynamicModule} from 'ng-dynamic-component';
import {ProductComponent} from './components/product/product.component';
import {SearchPageDynamicComponent} from './components/search-page-dynamic/search-page-dynamic.component';
import {AsyncPipe, isPlatformServer, NgComponentOutlet, NgIf} from '@angular/common';
import {PageService} from '@core/services/page.service';
import {LoadingIconComponent} from '@layout/components/loading-icon/loading-icon.component';
import {StoryblokComponentService} from '@core/services/storyblok-component.service';
import {ScrollPosService} from '@core/services/scroll-pos.service';
import {PageInfoActionsService} from '@core/services/page-info-actions.service';
import {PageInfoContextService} from '@core/services/page-info-context.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  standalone: true,
  imports: [AsyncPipe, NgIf, DynamicModule, ProductComponent, SearchPageDynamicComponent, NgComponentOutlet, LoadingIconComponent]
})
export class PageComponent implements OnInit, OnDestroy {

  pageInfo$: Observable<PageInfo | undefined>;
  storyblok: any;
  fragment: Subscription;
  pageInfoId: string;

  constructor(
    private routingService: RoutingService,
    private storyblokService: StoryblokService,
    private storyblokComponentService: StoryblokComponentService,
    private pageInfoService: PageInfoService,
    private pageService: PageService,
    private route: ActivatedRoute,
    protected scrollPosService: ScrollPosService,
    private pageInfoActionsService: PageInfoActionsService,
    private pageInfoContextService: PageInfoContextService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
  }

  ngOnInit(): void {
    this.pageInfo$ = this.getPageInfo(this.routingService.getURL(undefined, false), this.routingService.getQueryParams()['secret_key']);

    this.fragment = this.route.fragment.subscribe(() => {
      this.scrollPosService.restoreScrollPosition();
    });
  }

  ngOnDestroy() {
    this.fragment.unsubscribe();
  }

  getStoryblokComponents(type: any) {
    return this.storyblokComponentService.getComponent(type);
  }

  protected storyblokPageInfo(queryParam: Record<string, string>) {
    const promise = this.storyblokService.getStoryPromise(this.routingService.getURL(undefined, false), queryParam);

    return this.storyblokService.storyblokInputChange.pipe(
      switchMap((d) => this.storyblokService.storyblokEditCheck(d, promise)),
      map(d => {
        return {
          path: 'path',
          type: 'STORYBLOK_PAGE',
          pageData: {
            storyblok: JSON.stringify(d)
          }
        } as PageInfo;
      })
    );
  }

  protected getPageInfo(path: string, secret?: string): Observable<PageInfo | undefined> {
    let observable = this.pageInfoService.pageInfoGraphQL(path, secret);
    const queryParam = this.routingService.getQueryParams();
    if (this.storyblokService.isPreviewStoryblokPage(queryParam)) {
      observable = this.storyblokPageInfo(queryParam);
    }

    return observable.pipe(
      tap(data => {
        if (data) {
          this.pageChange(data)
        }
      })
    );
  }

  protected pageChange(data: PageInfo) {
    this.storyblok = this.pageInfoService.getStoryblockData(data);
    this.pageService.setPage(this.pageInfoService.getPageData(data));
    this.pageInfoContextService.setContext(data.context ?? []);
    if (isPlatformServer(this.platformId) && this.pageInfoId === data.id) {
      return;
    }
    this.pageInfoId = data.id ?? '';
    this.pageInfoActionsService.runActions(data.actions ?? []);
  }
}
