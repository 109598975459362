import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, computed,
  Inject,
  PLATFORM_ID, signal,
  WritableSignal
} from '@angular/core';
import {AlgoliaService} from '@core/services/algolia.service';
import {AlgoliaState} from '@core/interfaces/algolia-state';
import {AlgoliaProduct} from '@core/interfaces/algolia-product';
import {HitsContainerComponent} from './components/hits-container/hits-container.component';
import {SearchboxComponent} from './components/searchbox/searchbox.component';
import {CookieService} from '@core/services/cookie.service';
import {isPlatformBrowser, NgIf} from '@angular/common';
import {InstantSearchOptions} from 'instantsearch.js/es/types';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';

@Component({
  selector: 'app-quicksearch',
  templateUrl: './quicksearch.component.html',
  styleUrls: ['./quicksearch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HitsContainerComponent, SearchboxComponent, InstantSearchComponent, NgIf]
})
export class QuicksearchComponent {

  state: WritableSignal<AlgoliaState|undefined> = signal(undefined);
  private config: InstantSearchOptions;

  searchBoxIsOnFocus = signal(false);
  isResultsVisible = signal(false);

  hits = computed(() => {
    const state = this.state();
    if (!state) {
      return [];
    }

    const queryID = state.results?.queryID;
    let hits = (state.results?.hits || []) as AlgoliaProduct[];
    hits = hits.map((hit, index) => {
      hit.__position = index + 1;
      hit.__queryID = queryID;
      return hit;
    })
    return hits.filter(hit => !hit.pageWhitelist || hit?.pageWhitelist?.length === 0);
  })

  constructor(
    private algoliaService: AlgoliaService,
    private changeDetector: ChangeDetectorRef,
    private cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
  }

  setSearchboxFocus(focus: boolean) {
    this.searchBoxIsOnFocus.set(focus)
  }

  displaySearchContainer(event: boolean) {
    this.isResultsVisible.set(event)
  }

  disableResultsVisibility() {
    if (!this.isResultsVisible()) {
      return;
    }
    if (!this.searchBoxIsOnFocus()) {
      this.isResultsVisible.set(false);
      this.changeDetector.detectChanges();
    }
  }

  get searchConfig(): InstantSearchOptions {
    if (this.config) {
      return this.config;
    }
    this.config = {
      indexName: this.algoliaService.defaultIndex,
      searchClient: this.algoliaService.searchClient(),
      searchFunction: helper => {
        if (helper.state.query != '') {
          helper.search();
        }
      },
    };

    if (isPlatformBrowser(this.platformId)) {
      const useCookie = !!this.cookieService.get('_ALGOLIA');
      this.config.insights = {
        insightsInitParams: {
          useCookie,
        }
      }
    }

    return this.config;
  }

  stateChange(event: any) {
    if (event?.results) {
      this.state.set(event);
    }
  }
}
