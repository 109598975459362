import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Cart, CartItem, Order, ProductBase, WineProduct} from '../interfaces/generated/graphql';
import {ProductService} from './product.service';
import {PageService, PageWrapper} from './page.service';
import {Subscription} from 'rxjs';
import {formatPrice} from '../pipes/price.pipe';
import {isPlatformServer} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {

  lastVirtualProductView: string;

  pageSub: Subscription;

  constructor(
    private productService: ProductService,
    private pageService: PageService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.pageSub = this.pageService.getPageAsync().subscribe(page => {
      this.virtualPageView(page);
    })
  }

  public virtualPageView(page: PageWrapper | null) {
    if (!page?.isPathChanged) {
      return;
    }

    setTimeout(() => {
      this.dataLayerPush({
        event: 'virtualPageview',
        virtualPage: page?.page.path,
        pageTitle: page?.page.title
      })
    }, 1);
  }

  public beginCheckout(cart: Cart) {
    if (!cart.id) {
      return;
    }
    return this.dataLayerPush({
      event: 'begin_checkout',
      ecommerce: {
        value: formatPrice(cart.totalPrice),
        currency: 'DKK',
        coupon: cart.couponCode,
        items: this.getDatalayerFormatItems(cart)
      }
    }, true);
  }

  public productView(product: WineProduct) {
    if (!product?.id) {
      return;
    }
    if (product.id === this.lastVirtualProductView) {
      return;
    }
    this.lastVirtualProductView = product.id;
    return this.dataLayerPush({
      event: 'view_item',
      ecommerce: {
        value: this.productService.getDiscountPriceFromProduct(product),
        currency: 'DKK',
        items: [
          this.covertProductToDataLayerItem(product)
        ],
      }
    }, true);
  }

  public viewCart(cart: Cart) {
    if (!cart.id) {
      return;
    }
    return this.dataLayerPush({
      event: 'view_cart',
      ecommerce: {
        value: formatPrice(cart.totalPrice),
        currency: 'DKK',
        items: this.getDatalayerFormatItems(cart)
      }
    }, true);
  }

  public addProductToCartEvent(product: ProductBase, qty: number) {

    let value = this.productService.getDiscountPriceFromProduct(product, qty) * qty;
    value *= 100;
    value = Math.round(value);
    value /= 100;

    return this.dataLayerPush({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'DKK',
        value: value,
        items: [
          this.covertProductToDataLayerItem(product, qty)
        ]
      }
    }, true);

  }

  public addCartItemToCartEvent(cartItem: CartItem, qty: number) {
    return this.dataLayerPush({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'DKK',
        value: formatPrice(cartItem.qtyPrice) * qty,
        items: [
          this.covertCartItemToDataLayerItem(cartItem, qty)
        ]
      }
    }, true);

  }

  public addGiftcardToCartEvent(price: number) {
    return this.dataLayerPush({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'DKK',
        value: price,
        items: [
          {
            item_name: 'Gavekort',
            item_id: 'giftcard',
            price: price,
            discount: 0,
            quantity: 1
          }
        ]
      }
    }, true);
  }

  public updateCartEvent(cartItem: CartItem, qtyDiff: number) {
    if (qtyDiff < 0) {
      return this.removeFromCartEvent(cartItem, -qtyDiff);
    } else if (qtyDiff > 0) {
      return this.addCartItemToCartEvent(cartItem, qtyDiff);
    }
  }

  public removeFromCartEvent(cartItem: CartItem, qty: number) {
    return this.dataLayerPush({
      event: 'remove_from_cart',
      ecommerce: {
        currency: 'DKK',
        value: formatPrice(cartItem.qtyPrice) * qty,
        items: [
          this.covertCartItemToDataLayerItem(cartItem, qty)
        ]
      }
    }, true);
  }

  public addShippingInfoEvent(cart: Cart) {
    if (!cart.id) {
      return;
    }
    return this.dataLayerPush({
      event: 'add_shipping_info',
      ecommerce: {
        currency: 'DKK',
        value: formatPrice(cart.totalPrice),
        shipping_tier: cart.shippingMethod?.method,
        coupon: cart.couponCode,
        items: this.getDatalayerFormatItems(cart)
      }
    }, true);
  }

  public addPaymentInfo(cart: Cart, paymentMethod: string) {
    if (!cart.id) {
      return;
    }
    return this.dataLayerPush({
      event: 'add_payment_info',
      ecommerce: {
        currency: 'DKK',
        value: formatPrice(cart.totalPrice),
        payment_type: paymentMethod,
        coupon: cart.couponCode,
        items: this.getDatalayerFormatItems(cart)
      }
    }, true);
  }

  public purchase(order: Order | undefined) {
    if (!order?.id) {
      return;
    }
    const items = order.lineItems?.map((item) => {
      if (!item) {
        return;
      }
      return {
        item_id: this.getSku(item.sku || ''),
        item_name: item.productName,
        price: formatPrice(item.compareAtPriceVismaFormat),
        quantity: item.quantity,
        discount: formatPrice((item.compareAtPriceVismaFormat || 0) - (item.qtyPriceVismaFormat || 0))
      }
    });
    return this.dataLayerPush({
      event: 'purchase',
      isSuperpay: order.isSuperpay,
      ecommerce: {
        transaction_id: order.webOrderId,
        currency: 'DKK',
        value: formatPrice(order.totalVismaFormat),
        tax: formatPrice(order.taxVismaFormat),
        shipping: formatPrice(order.shippingPriceVismaFormat),
        coupon: order.couponCode,
        items: items
      }
    }, true);
  }

  public dataLayerPush(obj: object, ecommerce = false) {
    if (ecommerce) {
      // Clear the previous ecommerce object.
      this.dataLayer?.push({ecommerce: null});
    }

    this.dataLayer?.push(obj);
  }

  protected covertProductToDataLayerItem(product: ProductBase, qty = 0): object {
    return {
      item_name: product?.name,
      item_id: product?.sku,
      price: this.productService.getDefaultPriceFromProduct(product, 1),
      discount: this.productService.getDefaultPriceFromProduct(product, 1) - this.productService.getDiscountPriceFromProduct(product, qty),
      quantity: qty
    }
  }

  protected covertCartItemToDataLayerItem(cartItem: CartItem, qty = cartItem.qty): object {
    return {
      item_name: cartItem?.name,
      item_id: this.getSku(cartItem?.sku || ''),
      price: formatPrice(cartItem.compareAtPrice),
      discount: formatPrice(cartItem.compareAtPrice) - formatPrice(cartItem.qtyPrice),
      quantity: qty
    }
  }

  protected getSku(sku: string) {
    if (sku?.includes('giftcard')) {
      return 'giftcard';
    }
    return sku;
  }

  protected get ga(): (...args: any[]) => any {
    return (window as any).ga;
  }

  protected get dataLayer() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    return (window as any).dataLayer;
  }

  protected getDatalayerFormatItems(cart: Cart) {
    const items = (cart?.items || []).filter(Boolean) as CartItem[];
    return items?.map(item => this.covertCartItemToDataLayerItem(item));
  }

}
