import {Inject, Injectable, PLATFORM_ID, Signal, signal, WritableSignal} from '@angular/core';
import {Cart, CartItem, Mutation, Query} from '../interfaces/generated/graphql';
import {isPlatformBrowser} from '@angular/common';
import {ApolloService} from './apollo.service';
import {HubspotDealStage, HubspotService} from './hubspot.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ErrorService} from './error.service';
import {CheckoutContextService} from './checkout-context.service';
import {SessionService} from './session.service';
import {toObservable} from '@angular/core/rxjs-interop';

export interface CartWrapper {
  cart: Cart;
  loading: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CartService {

  private readonly emptyCart: Cart = {
    id: null,
    totalPrice: 0,
    itemsTotalQty: 0,
    items: [],
    sameAsBilling: true,
  }

  private _cartSignal: WritableSignal<CartWrapper> = signal<CartWrapper>({
    cart: this.emptyCart,
    loading: true
  });

  cartObservable = toObservable(this._cartSignal);

  constructor(
    private apollo: ApolloService,
    @Inject(PLATFORM_ID) private platformId: object,
    protected checkoutContextService: CheckoutContextService,
    private hubspotService: HubspotService,
    private modalService: NgbModal,
    protected errorService: ErrorService,
    private sessionService: SessionService,
  ) {
  }

  setCartId(cartId: any) {
    this.updateSessionCartId(cartId);
  }

  dropCart() {
    this.updateSessionCartId('');
  }

  getTestCase(cart: Cart, testCase: string): number {
    const cases = JSON.parse(cart.testCases || '{}')
    return cases[testCase];
  }

  addToCart(vismaId: string, productId: number, qty: number, eventSourceName: string, customPrice?: number, hitPosition?: number, isPreorder?: boolean) {
    this.setLoading();
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'addToCartMutation',
      variables: {
        vismaId,
        productId: +productId,
        qty: Math.min(1000000, qty),
        customPrice: customPrice ?? null,
        eventSourceName: eventSourceName,
        hitPosition
      }
    }).then(res => {
      const message = res.data?.addToCart?.message;
      if (message) {
        const item = res.data?.addToCart?.cart?.items?.find(item => item?.vismaId === vismaId);
        this.handleMessage(message, item as CartItem);
      }
      const cart = res.data?.addToCart?.cart;
      if (!cart) {
        return;
      }
      this.updateCart(cart);
      this.hubspotService.updateHubspotDeal(HubspotDealStage.cart);
      if (isPreorder) {
        this.openPresaleInfoMessage();
      }
      return cart;
    }).catch((reason) => {
      this.setCartToLoading(false);
      if (this.handleMessage(this.errorService.getErrorMessageFromGraphqlError(reason)?.message, undefined)) {
        return;
      }
      throw reason;
    });
  }

  updateItemInCart(qty: number, sku: string) {
    this.setLoading();
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'updateCartMutation',
      variables: {
        sku,
        qty: Math.min(1000000, qty),
      }
    }).then(res => {
      const message = res.data?.updateCart?.message;
      if (message) {
        const item = res.data?.updateCart?.cart?.items?.find(item => item?.sku === sku);
        this.handleMessage(message, item as CartItem);
      }

      const cart = res.data?.updateCart?.cart;
      if (!cart) {
        return;
      }
      this.updateCart(cart);
      this.checkoutContextService.updateCheckoutData(true, true, HubspotDealStage.cart);
      return cart;
    }).catch((reason) => {
      this.setCartToLoading(false);
      throw reason;
    })
  }

  removeItemFromCart(sku: string) {
    this.setLoading();
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'removeFromCartMutation',
      variables: {
        sku
      }
    }).then(res => {
      const cart = res.data?.removeFromCart;
      if (!cart) {
        return;
      }
      this.updateCart(cart);
      this.checkoutContextService.updateCheckoutData(true, true, HubspotDealStage.cart);
      return cart;
    }).catch((reason) => {
      this.setCartToLoading(false);
      throw reason;
    })
  }

  getCart() {
    return this.apollo.queryPromise<Query>({
      queryName: 'getCartQuery'
    }).then(res => {
      const cart = res.data?.getCart;
      if (!cart) {
        return;
      }
      this.updateCart(cart);
      return cart;
    })
  }

  get cartSignal() {
    return this._cartSignal.asReadonly();
  }

  updateCart(cart: Cart | null | undefined, loading = false) {
    this._cartSignal.set({
      cart: cart ?? this.emptyCart,
      loading: loading
    });
  }

  currentCart(): CartWrapper {
    return this._cartSignal();
  }

  cart(): Signal<CartWrapper> {
    if (!isPlatformBrowser(this.platformId)) {
      return signal({
        cart: this.emptyCart,
        loading: false
      });
    }
    return this._cartSignal;
  }

  setCartToLoading(loading = true) {
    this._cartSignal.set({
      loading: loading,
      cart: this._cartSignal().cart
    })
  }

  private handleMessage(message: string | null | undefined, cartItem: CartItem | undefined): boolean {
    if (!message) {
      return false;
    }
    if (message && message === 'MAX_QTY_EXCEEDED') {
      this.openMaxQtyModal(cartItem);
      return true;
    }
    if (message && message === 'NO_QTY_AVAILABLE') {
      this.openOutOfStockModal();
      return true;
    }
    if (message === 'MAX_LIMIT_QTY_EXCEEDED') {
      this.openQtyMaxLimitModal();
      return true;
    }
    if (message === 'ADDED_PRODUCT_MUST_MATCH_OTHER_PRODUCT_SALES_STATUSES') {
      this.openPresaleErrorMessage();
      return true;
    }
    return false;
  }

  private openMaxQtyModal(cartItem?: CartItem) {
    import('../../content/layout/components/product-stock-limit-modal/product-stock-limit-modal.component').then(comp => {
      const modalRef = this.modalService.open(comp.ProductStockLimitModalComponent);
      modalRef.componentInstance.maxQty = cartItem?.qty;
      modalRef.componentInstance.unit = cartItem?.unit;
    })
  }

  updateSessionCartId(cartId: string) {
    const session = this.sessionService.getCurrentSession();
    if (session && session.cartId !== cartId) {
      this.updateSessionCartIdMutation(cartId).then((sessionResponse) => {
        const updatedSession = sessionResponse.data?.updateSession?.userSession;
        this.sessionService.setSession(updatedSession);
      });
    }
  }

  private updateSessionCartIdMutation(cartId: string) {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'updateSessionMutation',
      variables: {
        cartId
      }
    });
  }

  private openQtyMaxLimitModal() {
    import('../../content/layout/components/product-qty-max-limit-modal/product-qty-max-limit-modal.component').then(comp => {
      this.modalService.open(comp.ProductQtyMaxLimitModalComponent);
    })
  }

  private openPresaleErrorMessage() {
    import('../../content/layout/components/pre-order-error-message-modal/pre-order-error-message-modal.component').then(comp => {
      this.modalService.open(comp.PreOrderErrorMessageModalComponent);
    })
  }

  private openOutOfStockModal() {
    import('../../content/layout/components/product-out-of-stock-modal/product-out-of-stock-modal.component').then(comp => {
      this.modalService.open(comp.ProductOutOfStockModalComponent);
    })
  }

  private setLoading() {
    this.setCartToLoading();
    this.checkoutContextService.setLoading();
  }

  private openPresaleInfoMessage() {
    import('../../content/layout/components/pre-order-info-message-modal/pre-order-info-message-modal.component').then(comp => {
      this.modalService.open(comp.PreOrderInfoMessageModalComponent);
    })
  }
}
