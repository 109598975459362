import {Component, EventEmitter, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NgIf} from '@angular/common';
import {PowerStopService} from '../../../../../core/services/power-stop.service';

@Component({
  selector: 'app-add-to-cart-popup',
  templateUrl: './add-to-cart-popup.component.html',
  styleUrls: ['./add-to-cart-popup.component.scss'],
  standalone: true,
  imports: [
    NgIf
  ]
})
export class AddToCartPopupComponent {

  @Output() buyMore = new EventEmitter();

  constructor(
    private router: Router,
    private powerStopService: PowerStopService
  ) {
  }

  goToCheckout() {
    this.powerStopService.goToPowerStop('addToCartPopup');
  }

  notOnCartPage() {
    return this.router.url !== '/checkout/cart';
  }

}
