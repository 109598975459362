import {ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, Input, OnInit, Signal} from '@angular/core';
import {AlgoliaService} from '@core/services/algolia.service';
import {AlgoliaWineProduct} from '@core/types/algolia-wine-product';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {AsyncPipe, CurrencyPipe, NgIf} from '@angular/common';
import {ProductService} from '@core/services/product.service';
import {QtyInputComponent} from '@layout/components/qty-input/qty-input.component';
import {CartService} from '@core/services/cart.service';
import {LoadingComponent} from '@layout/components/loading/loading.component';
import {VfSecretKeyService} from '@core/services/vf-secret-key.service';
import {AlgoliaProduct} from '@core/interfaces/algolia-product';
import {Cart} from '@core/interfaces/generated/graphql';
import {faBasketShopping} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-wine-festival-product',
  templateUrl: './wine-festival-product.component.html',
  styleUrls: ['./wine-festival-product.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    QtyInputComponent,
    FontAwesomeModule,
    CurrencyPipe,
    LoadingComponent,
    NgIf,
    AsyncPipe
  ]
})
export class WineFestivalProductComponent implements OnInit {

  qty: number;
  loading = false;
  success = false;
  itemQtySignal: Signal<number>;
  product: AlgoliaWineProduct;

  @Input() set hit(value: AlgoliaProduct) {
    const product = this.algoliaService.hitToWineProduct(value) as AlgoliaWineProduct;
    product.vf_id = value.vf_id;
    this.product = product;
  };

  constructor(
    private algoliaService: AlgoliaService,
    private productService: ProductService,
    private cartService: CartService,
    private changeDetectorRef: ChangeDetectorRef,
    private vfSecretKeyService: VfSecretKeyService,
  ) {
  }

  get price(): number {
    return this.productService.getDiscountPriceFromProduct(this.product, this.product.defaultQuantity || 1);
  }

  qtyInCart(cart: Cart): number {
    return cart.items?.find(item => item?.vismaId === this.product.vismaProductNumber)?.qty || 0;
  }

  ngOnInit() {
    this.qty = this.product.defaultQuantity || 1;
    this.itemQtySignal = computed(() => {
      return this.qtyInCart(this.cartService.cartSignal().cart);
    });
  }

  addToCart(setFocusInSearchBox = false) {
    this.loading = true;

    if (setFocusInSearchBox && this.vfSecretKeyService.isVfSecretKeySet()) {
      this.setFocusInSearchBox();
    }

    this.cartService.addToCart(this.product.vismaProductNumber, this.product.productId as any, this.qty, 'Wine-festival').then(() => {
      this.loading = false;
      this.showSuccess();
      this.qty = this.product.defaultQuantity || 1;
      this.changeDetectorRef.detectChanges();
    }).catch((reason) => {
      this.loading = false;
      this.qty = this.product.defaultQuantity || 1;
      this.changeDetectorRef.detectChanges();
    });
  }

  showSuccess() {
    this.success = true;
    setTimeout(() => {
      this.success = false;
      this.changeDetectorRef.detectChanges();
    }, 2000);
  }

  protected setFocusInSearchBox() {
    const qtyInput = document.querySelector('#vf-searchbox') as any;
    qtyInput?.focus();
    qtyInput?.select();
  }

  protected readonly faBasketShopping = faBasketShopping;
}
