import {ChangeDetectionStrategy, Component, computed, Inject, Input, PLATFORM_ID} from '@angular/core';
import {Cart, WineProduct} from '@core/interfaces/generated/graphql';
import {CartService} from '@core/services/cart.service';
import {Observable} from 'rxjs';

import {CountdownLiveComponent} from '@layout/components/countdown-live/countdown-live.component';
import {PriceTableComponent} from '@layout/components/price-table/price-table.component';
import {ProductRatingsComponent} from '../product-ratings/product-ratings.component';
import {ProductOrderComponent} from '../product-order/product-order.component';
import {AsyncPipe, CurrencyPipe, isPlatformBrowser, NgIf, NgOptimizedImage} from '@angular/common';
import {StoryblokService} from '@core/services/storyblok.service';
import {map} from 'rxjs/operators';
import {Pricing, ProductService} from '@core/services/product.service';
import {formatPrice} from '@core/pipes/price.pipe';
import {environment} from '@env/environment';
import {AlgoliaInsightsService} from '@core/services/algolia-insights.service';
import {RouterModule} from '@angular/router';
import {GroupVoucherService} from '@core/services/groupVoucher.service';
import {GridService} from '@core/services/grid.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductTopCtaComponent} from '@layout/components/product-top-cta/product-top-cta.component';

@Component({
  selector: 'app-product-right',
  templateUrl: './product-right.component.html',
  styleUrls: ['./product-right.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgOptimizedImage, NgIf, CurrencyPipe, CountdownLiveComponent, PriceTableComponent, ProductRatingsComponent, ProductOrderComponent, AsyncPipe, RouterModule, ProductTopCtaComponent]
})
export class ProductRightComponent {

  @Input() product: WineProduct | undefined;

  cart: Cart;

  totalPriceSignal = computed(() => {
    return this.getTotalPrice(this.cartService.cartSignal().cart);
  });

  constructor(
    public storyblokService: StoryblokService,
    public cartService: CartService,
    private productService: ProductService,
    private algoliaInsightsService: AlgoliaInsightsService,
    private groupVoucherService: GroupVoucherService,
    private gridService: GridService,
    private modal: NgbModal,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
  }

  getTotalPrice(cart: Cart): number {
    const totalPrice = environment.supervin.freeDelivery - formatPrice(cart?.totalPrice);
    if (totalPrice) {
      return totalPrice;
    }
    return environment.supervin.freeDelivery;
  }

  get lastPrice$(): Observable<Pricing> | undefined {
    if (!this.product) {
      return undefined;
    }
    return this.productService.getDefaultPriceAsync(this.product);
  }

  hasGroupVoucherPrice(): Observable<boolean> {
    return this.groupVoucherService.getGroupVoucher().pipe(
      map(groupVoucher => {
          return !!groupVoucher?.prices?.find(d => d?.vismaId == this.product?.vismaProductNumber);
        }
      ));
  }

  get isMobile() {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    return !this.gridService.isLarge;
  }

  showFreeShipping(): Observable<boolean> {
    return this.groupVoucherService.getGroupVoucher().pipe(
      map(groupVoucher => {
          return !groupVoucher?.delivery;
        }
      ));
  }

  addedToCart() {
    if (!this.product?.id) {
      return;
    }
    const queryId = history.state.queryID;
    this.algoliaInsightsService.clickedAddToCart(this.product?.id, queryId);
  }

  openEnergyLabelDoc() {
    import('../../../../../../layout/components/image-modal/image-modal.component').then(comp => {
      const ref = this.modal.open(comp.ImageModalComponent);
      ref.componentInstance.title = 'Energimærke';
      ref.componentInstance.imgSrc = this.product?.meta?.energyLabelDoc;
    })

  }
}
