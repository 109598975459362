import {Injectable, NgZone, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {PageService} from '@core/services/page.service';
import {TrackEventService} from '@core/services/track-event.service';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TrackTimeOnPageService implements OnDestroy {
  private secondsMapping = [
    {secondsOnPage: 0, waitingSeconds: 5},
    {secondsOnPage: 30, waitingSeconds: 15},
    {secondsOnPage: 120, waitingSeconds: 30},
    {secondsOnPage: 300, waitingSeconds: 45},
    {secondsOnPage: 600, waitingSeconds: 60},
    {secondsOnPage: 1200, waitingSeconds: 120},
    {secondsOnPage: 1800, waitingSeconds: 180},
    {secondsOnPage: 3600, waitingSeconds: 300}
  ];

  private start: Date;
  private timeout: any;
  private pageSub: Subscription;

  constructor(
    private pageService: PageService,
    private trackEventService: TrackEventService,
    private ngZone: NgZone
  ) {
  }

  ngOnDestroy() {
    this.clearTimeout();
  }

  public init() {
    this.pageSub?.unsubscribe();
    this.pageSub = this.pageService.getPageAsync().pipe(
      filter(page => page.isPathChanged),
    ).subscribe(() => this.startTracking());
  }

  private startTracking() {
    this.start = new Date();
    this.clearTimeout();
    this.iterate();
  }

  private iterate() {
    const seconds = this.secondsBetweenDates(this.start, new Date());
    const waitingSeconds = this.getNextValueInMap(this.secondsMapping, seconds) || 300;
    this.timeout = this.ngZone.runOutsideAngular(() => setTimeout(() => {
      this.trackEvent('page.viewing', {waitingSeconds, seconds: seconds + waitingSeconds});
      this.iterate();
    }, waitingSeconds * 1000));
  }

  private secondsBetweenDates(date1: Date, date2: Date) {
    return Math.floor((date2.getTime() - date1.getTime()) / 1000);
  }

  private getNextValueInMap(map: { secondsOnPage: number, waitingSeconds: number }[], key: number) {
    const array = map.sort((a, b) => b.secondsOnPage - a.secondsOnPage);
    return array.find(k => key >= k.secondsOnPage)?.waitingSeconds;
  }

  private trackEvent(event: string, data: Record<string, any>) {
    this.trackEventService.trackEvent(event, data);
  }

  private clearTimeout() {
    this.timeout && clearTimeout(this.timeout);
  }
}
