import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostBinding,
  Inject,
  input,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import {StoryblokStylingService} from '@core/services/storyblok-styling.service';
import {AsyncPipe, isPlatformBrowser, NgIf} from '@angular/common';
import {StoryblokDirective} from '@core/directives/storyblok.directive';
import {SafeHtmlPipe} from '@core/pipes/safe-html.pipe';
import {StoryblokDynamicInputService} from '@core/services/storyblok-dynamic-input.service';
import {GridService} from '@core/services/grid.service';
import {StoryblokReadMoreComponent} from '@modules/storyblok/components/storyblok-read-more.component';
import {ReadMoreComponent} from '@layout/components/read-more/read-more.component';

@Component({
  selector: 'app-storyblok-tinymce',
  templateUrl: './storyblok-tinymce.component.html',
  styleUrls: ['./storyblok-tinymce.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoryblokDirective, SafeHtmlPipe, NgIf, AsyncPipe, ReadMoreComponent]
})
export class StoryblokTinymceComponent extends StoryblokReadMoreComponent implements OnInit, AfterViewInit {

  @HostBinding('class') class: any;

  general_styling = input<any>();
  _editable = input<any>();

  content = input<{ content: string }>()

  contentData = computed(() => {
    return this.storyblokDynamicInputService.replaceDynamicInputs(this.content()?.content ?? '')();
  })

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    gridService: GridService,
    private storyblokDynamicInputService: StoryblokDynamicInputService,
    private storyblokStylingService: StoryblokStylingService,
    @Inject(PLATFORM_ID) platformId: object
  ) {
    super(gridService, platformId);
  }

  ngOnInit(): void {
    this.class = this.storyblokStylingService.getClassList(this.general_styling(), {
      desktop: {
        padding: '20px'
      }
    });
  }

  ngAfterViewInit() {
    this.replaceAllScripts();
  }

  replaceAllScripts() {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    this.elementRef.nativeElement.querySelectorAll('script').forEach((script: { [index: string]: any }) => {
      const newScript: { [index: string]: any } = document.createElement('script');
      Object.keys(script).forEach((attr) => newScript[attr] = script[attr]);

      newScript['innerHTML'] = script['innerHTML'];
      newScript['type'] = script['type'];
      newScript['src'] = script['src'];

      script['replaceWith'](newScript);
    });
  }
}
