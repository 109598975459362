import {ChangeDetectionStrategy, Component, Signal} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GroupVoucherService, GroupVoucherWrapper} from '@core/services/groupVoucher.service';
import {RouterModule} from '@angular/router';
import {AsyncPipe, NgIf} from '@angular/common';
import {TrackEventService} from '@core/services/track-event.service';


@Component({
  selector: 'app-header-middle-voucher',
  templateUrl: './header-middle-voucher.component.html',
  styleUrls: ['./header-middle-voucher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, RouterModule, AsyncPipe]
})
export class HeaderMiddleVoucherComponent {
  public groupVoucher: Signal<GroupVoucherWrapper>

  constructor(
    private modalService: NgbModal,
    private voucherService: GroupVoucherService,
    private trackEventService: TrackEventService
  ) {
    this.groupVoucher = this.voucherService.groupVoucherSignal;
  }

  openModal() {
    this.trackEventService.trackClickEvent('voucher_modal_open');
    import('../header-middle-voucher-modal/header-middle-voucher-modal.component').then(comp => {
      this.modalService.open(comp.HeaderMiddleVoucherModalComponent);
    });
  }

}
