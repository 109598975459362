import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  signal,
  VERSION as AngularVersion,
} from '@angular/core';
import {InstantSearch, InstantSearchOptions, Widget} from 'instantsearch.js/es/types';
import {VERSION} from '../version';
import instantsearch from 'instantsearch.js/es';
import {SearchParameters, SearchResults} from 'algoliasearch-helper';

export interface ChangeEvent {
  results: SearchResults | undefined | null
  state: {};
}

@Component({
  selector: 'app-instantsearch',
  standalone: true,
  imports: [],
  template: '<ng-content></ng-content>'
})
export class InstantSearchComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() public config: InstantSearchOptions;
  @Input() public instanceName: string = 'default';

  @Output() changeEvent: EventEmitter<ChangeEvent> = new EventEmitter<ChangeEvent>();

  public instantSearchInstance: InstantSearch;
  private _currentState = signal<SearchParameters | null>(null);

  constructor(@Inject(PLATFORM_ID) public platformId: Object) {}

  get currentState() {
    return this._currentState.asReadonly();
  }

  public ngOnInit() {
    if (typeof this.config.searchClient.addAlgoliaAgent === 'function') {
      this.config.searchClient.addAlgoliaAgent(
        `angular (${AngularVersion.full})`
      );
      this.config.searchClient.addAlgoliaAgent(
        `supervin custom (${VERSION})`
      );
    }

    this.instantSearchInstance = instantsearch(this.config);
    this.instantSearchInstance.on('render', this.onRender);
  }

  public ngAfterViewInit() {
    this.instantSearchInstance.start();
  }

  public ngOnDestroy() {
    if (this.instantSearchInstance) {
      this.instantSearchInstance.removeListener('render', this.onRender);
      this.instantSearchInstance.dispose();
    }
  }

  public addWidgets(widgets: Widget[]) {
    this.instantSearchInstance.addWidgets(widgets);
  }

  public removeWidgets(widgets: Widget[]) {
    this.instantSearchInstance.removeWidgets(widgets);
  }

  public refresh() {
    this.instantSearchInstance.refresh();
  }

  onRender = () => {
    const helper = this.instantSearchInstance.helper;
    if (!helper) {
      return;
    }
    this._currentState.set(helper.state);
    this.changeEvent.emit({
      results: helper.lastResults,
      state: helper.state,
    });
  };
}
