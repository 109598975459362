import {Component, HostBinding, TemplateRef} from '@angular/core';
import {ToastService} from '../../../../core/services/toast.service';

import {NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  imports: [NgIf, NgbToastModule, NgTemplateOutlet, NgForOf]
})
export class ToastComponent {

  @HostBinding('class') class = 'ngb-toasts';

  constructor(public toastService: ToastService) {
  }

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
