import {Component, computed, Input} from '@angular/core';
import {CartService} from '@core/services/cart.service';
import {UserInitService} from '@core/services/user-init.service';
import {GroupVoucherService} from '@core/services/groupVoucher.service';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
  selector: 'app-superpay-buy-btn',
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe
  ],
  templateUrl: './superpay-buy-btn.component.html',
  styleUrls: ['./superpay-buy-btn.component.scss']
})
export class SuperpayBuyBtnComponent {

  @Input() type: 'checkout'|'add-to-cart' = 'checkout';
  @Input() productId: string|undefined;
  @Input() referer: string|undefined;
  @Input() label: string = ' - KLIK HER';
  @Input() qty: number|undefined;

  cartSignal = this.cartService.cartSignal;
  groupVoucherSignal = this.groupVoucherService.groupVoucherSignal;
  renderSignal = this.initRenderSignal();

  constructor(
    protected cartService: CartService,
    protected initService: UserInitService,
    private groupVoucherService: GroupVoucherService
  ) {
  }

  initRenderSignal() {
    return computed(() => {
      const cart = this.cartSignal()?.cart;
      if (!cart?.id) {
        return false;
      }
      if (!this.initService.onInitializedSignal()) {
        return false;
      }
      if (this.groupVoucherSignal().groupVoucher?.delivery === 1) {
        return false;
      }
      return this.cartService.getTestCase(cart, 'show_sp_iframe_btn') === 1;
    })
  }

}
