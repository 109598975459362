import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {WineProduct} from '../../../../../core/interfaces/generated/graphql';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {ProductService} from '../../../../../core/services/product.service';
import {StoryblokStylingService} from '../../../../../core/services/storyblok-styling.service';
import {StoryblokDirective} from '../../../../../core/directives/storyblok.directive';

import {ProductCarouselComponent} from '../../../../layout/components/product-carousel/product-carousel.component';
import {AsyncPipe, NgForOf} from '@angular/common';
import {StoryblokBaseComponent} from '../storyblok-base.component';

@Component({
  selector: 'app-storyblok-product-carousel',
  templateUrl: './storyblok-product-carousel.component.html',
  styleUrls: ['./storyblok-product-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StoryblokDirective, NgForOf, AsyncPipe, ProductCarouselComponent]
})
export class StoryblokProductCarouselComponent extends StoryblokBaseComponent implements OnInit {
  @Input() visma_ids: string;
  @Input() _editable: any;
  @Input() general_styling: any;

  @Input()
  set elementId(value: any) {
    this.hostElementId = value;
  }

  @HostBinding('attr.id') hostElementId: any;

  @HostBinding('class') class: any;

  products$: Observable<WineProduct[]>;
  errors: string[];

  constructor(
    private storyblokStylingService: StoryblokStylingService,
    private productService: ProductService
  ) {
    super()
  }

  ngOnInit() {
    if (this.visma_ids) {
      const productObservable = this.visma_ids.split(',');
      this.products$ = this.productService.getProducts(productObservable).pipe(
        tap((data: any) => this.errors = data?.map((item: any) => item.error)),
        map((data: any) => data?.map((item: any) => item.product)?.sort((x: any, y: any) => this.sortOutOfStock(x, y)))
      );
    }

    this.class = this.getClass();
  }

  sortOutOfStock(x: WineProduct, y: WineProduct): number {
    return (x.inStock === y.inStock) ? 0 : x.inStock ? -1 : 1;
  }

  getClass(): string[] {
    return this.storyblokStylingService.getClassList(this.general_styling, undefined, {'col-': 12});
  }
}
