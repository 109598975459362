import {Injectable, NgZone} from '@angular/core';
import {record} from 'rrweb';
import {UserInitService} from '@core/services/user-init.service';
import {Mutation} from '@core/interfaces/generated/graphql';
import {ApolloService} from '@core/services/apollo.service';
import {captureMessage} from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root'
})
export class RrwebService {


  protected rrwebWorker: Worker | undefined;

  callback = (message: MessageEvent<any>) => {
    this.persistEvents(message.data);
  }

  constructor(
    private userInitService: UserInitService,
    private apollo: ApolloService,
    protected zone: NgZone
  ) {
    if (typeof Worker !== 'undefined') {
      (window as any)._rrwebService = this;
      this.rrwebWorker = new Worker(new URL('../workers/rrweb-storage.worker', import.meta.url), {type: 'module'});
      this.zone.runOutsideAngular(() => {
        this.rrwebWorker?.addEventListener('message', this.callback)
      })
    }
  }

  public takeFullSnapshot(isCheckout: boolean = false) {
    this.zone.runOutsideAngular(() => {
      record.takeFullSnapshot(isCheckout);
    })
  }

  async init() {

    await this.userInitService.onInitializedPromise();
    this.zone.runOutsideAngular(() => {
      record({
        maskAllInputs: true,
        slimDOMOptions: true,
        inlineStylesheet: false,
        sampling: {
          scroll: 400,
          media: 500,
          input: 'last'
        },
        emit: (event) => {
          if ((event.data as any)?.adds && (event.data as any)?.adds.length > 1000) {
            this.takeFullSnapshot();
            return;
          }

          this.rrwebWorker?.postMessage(event);
        }
      });
    })
  }

  public persistEvents(data: any) {
    return this.apollo.mutatePromise<Mutation>({
      queryName: 'saveRRWebMutation',
      variables: data
    }).catch((e) => {
      captureMessage('Failed to save rrweb events', {
        level: 'warning',
        extra: {
          eventsSize: this.byteCount(data.events),
          hashMapSize: this.byteCount(data.hash_map),
          error: e,
          networkError: e.networkError?.message,
        }
      });
    });
  }

  protected byteCount(s: string) {
    return new Blob([s]).size;
  }

}
