import {Component, Input} from '@angular/core';
import {Rating, WineProduct} from '../../../../../../../core/interfaces/generated/graphql';

import {ProductRatingComponent} from '../../../../../../layout/components/product-rating/product-rating.component';
import {NgForOf, NgIf} from '@angular/common';

@Component({
  selector: 'app-product-ratings',
  templateUrl: './product-ratings.component.html',
  styleUrls: ['./product-ratings.component.scss'],
  standalone: true,
  imports: [NgIf, NgForOf, ProductRatingComponent]
})
export class ProductRatingsComponent {

  @Input() product: WineProduct | undefined;

  get ratings(): Rating[] {
    const ratings = this.product?.meta?.ratings;
    if (!ratings) {
      return [];
    }
    return ratings.filter(d => !!d) as Rating[];
  }

}
