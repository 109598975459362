<div class="header-middle">
  <div class="container">
    <div class="row justify-content-between">
      <div class="col-6 col-lg-2 align-self-center header-Logo" data-rr-shared="true">
        <a [appRouterContext]="'supervinLogo'" [title]="'Gå til forsiden'" class="logo-link" routerLink="/">
          <img
            [ngSrc]="this.storyblokService.getImageUrl({filename: 'https://a.storyblok.com/f/106593/5459x1241/65f115b636/supervin_hvidorange_highres.png?cv=1717662140574'})"
            alt="SuperVin"
            height="41"
            width="181"
          />
        </a>
      </div>

      <!--Hide this on desktop, shows only on mobile-->
      <div id="show-content-mobile" class="col-6 col-lg-8 header-icons">
        <app-header-middle-voucher></app-header-middle-voucher>
        <div class="user-login">
          <i
            *ngIf="(currentUserSubject | async) !== null"
            (click)="openWelcomeModal()"
            class="supervin-icon-login"
          ></i>
          <i
            *ngIf="(currentUserSubject | async) === null"
            (click)="open()"
            class="supervin-icon-login"
          ></i>
        </div>
        <app-cart-small></app-cart-small>
        <fa-icon (click)="toggleMenu.emit()" class="mobile-menu" [icon]="faBars"></fa-icon>
      </div>

      <div class="col-12 col-md-12 col-lg-6 header-quicksearch borderRight borderLeft">
        <app-trustpilot [styleHeightPx]="37" theme="dark" class="hide-content-mobile"></app-trustpilot>
        <app-quicksearch></app-quicksearch>
      </div>

      <!--Show this on desktop, hide this on mobile-->
      <div class="col-12 col-lg-4 header-icons hide-content-mobile">
        <div class="col-8 d-flex justify-content-evenly borderRight">

          <div class="hide-content-mobile link-firmagaver" data-rr-shared="true">
            <a [appRouterContext]="'headerMenu'" routerLink="/info/b2b-salg" title="Firmagaver">
              <img
                [ngSrc]="this.storyblokService.getImageUrl({filename: 'https://a.storyblok.com/f/106593/x/f18cb628cf/firmagaver2.svg'})"
                alt="Firmagaver" height="35" width="35">
              <p class="link-firmagaver-text">Firmagaver</p>
            </a>
          </div>

          <app-header-middle-voucher class="lower-item"></app-header-middle-voucher>

          <div class="user-login lower-item">
            <i (click)="open()" class="supervin-icon-login" id="little-exstra-margin-bottom"></i>
            <a (click)="open()" *ngIf="(currentUserSubject | async) === null">
              Kontoadgang
            </a>
            <span *ngIf="(currentUserSubject | async) !== null" class="hide-content-mobile">
              <a (click)="goToUserUniverse()">Konto</a>
              <a (click)="openWelcomeModal()"> | Log ud</a>
            </span>
          </div>
        </div>
        <app-cart-small class="col-4 lower-item header-Cart"></app-cart-small>
      </div>
    </div>
  </div>
</div>


