import {
  Component,
  effect,
  forwardRef,
  HostBinding,
  Inject,
  input,
  Input,
  OnDestroy,
  signal,
  ViewChild
} from '@angular/core';
import {GridService} from '@core/services/grid.service';
import {Subscription} from 'rxjs';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {DesktopTopFilterComponent} from '../desktop-top-filter/desktop-top-filter.component';
import {NgClass, NgIf} from '@angular/common';
import {AlgoliaFacetFilter} from '@core/types/algolia-filter';
import {HEADER_HEIGHT} from '@core/utils/HeaderHeight';
import {InstantSearchComponent} from '@core/instantsearch/components/instantsearch.component';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {
  ClearRefinementsComponent
} from '@modules/page/components/search-page/components/clear-refinements/clear-refinements.component';

@Component({
  selector: 'app-top-filter',
  templateUrl: './top-filter.component.html',
  styleUrls: ['./top-filter.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, NgIf, NgClass, DesktopTopFilterComponent, ClearRefinementsComponent]
})
export class TopFilterComponent implements OnDestroy {

  instantSearchChangeSubscription: Subscription;
  @Input() filters: AlgoliaFacetFilter[] = [];
  public isCollapsed = signal(true);
  private searchParams: string;

  @Input() hits: any;

  @ViewChild('filter') filter: any;

  @HostBinding('class') class: any;

  hasLoadedHits = input(false);

  constructor(
    private gridService: GridService,
    @Inject(forwardRef(() => InstantSearchComponent))
    public instantSearchParent: InstantSearchComponent,
  ) {

    this.instantSearchChangeSubscription = this.instantSearchParent.changeEvent.subscribe(
      data => {
        const currentSearchParams = (data.results as any)?.params;
        if (!this.isCollapsed() && this.searchParams !== currentSearchParams) {
          document.getElementById('supervin-instantsearch')?.scrollIntoView();
        }
        this.searchParams = currentSearchParams;
      }
    );

    effect(() => {
      let height = 'auto';
      if (!this.isDesktop()) {
        height = (this.gridService.screenHeight() - 165 - HEADER_HEIGHT) + 'px'
      }
      document.getElementById('filter-height')?.setAttribute('style', 'height: ' + height);
    });
  }

  ngOnDestroy() {
    this.instantSearchChangeSubscription.unsubscribe();
  }

  get isDesktop() {
    return this.gridService.isMediumSignal;
  }

  showFilterRule() {
    document.body.classList.add('stop-scrolling');
    this.class = 'show-filter';
  }

  hideFilterRule() {
    document.body.classList.remove('stop-scrolling');
    this.class = ''
  }

  scrollToFilterTop() {
    const filter = this.filter.nativeElement.getBoundingClientRect();
    if (filter.top !== HEADER_HEIGHT) {
      this.filter.nativeElement.scrollIntoView();
    }
  }

  get shownFilterAttributes() {
    return this.shownFilters
      .map(filter => filter.algoliaAttribute) as string[];
  }

  get shownFilters() {
    return this.filters
      .filter(filter => ['refinement-list', 'ratings-filter', 'range-slider', 'range-input'].indexOf(filter.instantSearchType ?? '') !== -1)
  }

  collapse(value: boolean) {
    if (this.isDesktop()) {
      this.isCollapsed.set(false)
    } else {
      this.isCollapsed.set(value);

      this.scrollToFilterTop();

      if (value) {
        this.hideFilterRule();
      } else {
        this.showFilterRule();
      }
    }
  }

  protected readonly faPlus = faPlus;
}
