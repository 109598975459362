import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {RoutingService, UrlFormatted} from '../../../../core/services/routing.service';

import {RouterModule} from '@angular/router';
import {NgIf, NgOptimizedImage, NgStyle} from '@angular/common';
import {RouterContextDirective} from '@core/directives/router-context.directive';

export interface SpotObj {
  __typename?: 'FrontPageSpot';
  backgroundImage?: string;
  columnsLg?: string;
  columnsMd?: string;
  columnsSm?: string;
  ctaDesign?: string;
  ctaLink?: string;
  ctaText?: string;
  description?: string;
  height?: string;
  id?: string | number;
  placement?: string;
  title?: string;
  imgHeight?: number;
  imgWidth?: number;
}

@Component({
  selector: 'app-frontpage-spot',
  templateUrl: './frontpage-spot.component.html',
  styleUrls: ['./frontpage-spot.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgStyle, NgIf, RouterModule, NgOptimizedImage, RouterContextDirective]
})
export class FrontpageSpotComponent {

  url: UrlFormatted | undefined;

  get spot(): SpotObj {
    return this._spot;
  }

  @Input()
  set spot(value: SpotObj) {
    this._spot = value;
    this.url = this.routingService.getUrlFormattedObjectByPath(<string>value?.ctaLink);
  }

  private _spot: SpotObj;

  constructor(
    private routingService: RoutingService
  ) {
  }

  get style() {
    const style: any = {};

    if (this.spot?.height) {
      style.height = `${this.spot.height}px`;
    }

    return style;
  }

}
