import {Component, HostListener, input, Input, Signal, ViewChild} from '@angular/core';
import {NgIf} from '@angular/common';
import {HeaderMenuService} from '@core/services/header-menu.service';
import {map} from 'rxjs/operators';
import {GridService} from '@core/services/grid.service';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {toSignal} from '@angular/core/rxjs-interop';
import {FaIconComponent} from '@fortawesome/angular-fontawesome';
import {RouterLink} from '@angular/router';
import {
  MenuAnnouncekitComponent
} from '@layout/components/header/components/header-bottom/menu-announcekit/menu-announcekit.component';
import {RouterContextDirective} from '@core/directives/router-context.directive';
import {ClickTrackingDirective} from '@core/directives/click-tracking.directive';

@Component({
  selector: 'li[app-header-menu-item]',
  templateUrl: './header-menu-item.component.html',
  styleUrls: ['./header-menu-item.component.scss'],
  standalone: true,
  imports: [NgIf, FaIconComponent, RouterLink, MenuAnnouncekitComponent, RouterContextDirective, ClickTrackingDirective]
})
export class HeaderMenuItemComponent {
  showSubMenu: Signal<boolean | undefined>;

  @ViewChild('announcekitComponent') announceKit: MenuAnnouncekitComponent;

  @Input() name: string;
  @Input() hasSubMenu: boolean;
  @Input() url: string;

  showAnnounceKit = input<boolean>(false);

  constructor(
    private headerMenuService: HeaderMenuService,
    private gridService: GridService
  ) {


    this.showSubMenu = toSignal(this.headerMenuService.activeMenu$.pipe(
      map((activeMenu) => {
        return activeMenu === this.name;
      })
    ));

  }

  menuItemClick() {
    this.headerMenuService.toggleMenu(this.name);
  }

  announceKitClick(event: MouseEvent) {
    this.announceKit.announceKitClick(event);
  }

  @HostListener('mouseenter', ['$event'])
  mouseenter(event: any) {
    setTimeout(() => {
      this.headerMenuService.setActiveMenu(this.name);
    })
  }

  @HostListener('mouseleave', ['$event'])
  mouseleave(event: any) {
    this.headerMenuService.setActiveMenu(null);
  }

  closeMenu() {
    if (this.gridService.isLarge) {
      this.headerMenuService.setActiveMenu(null);
    }
  }

  getAdditionalContext(name: string) {
    return {
      menu_navigation_path: name
    }
  }

  protected readonly faAngleDown = faAngleDown;
}
