import {
  Component,
  computed,
  effect,
  ElementRef,
  input,
  signal,
  Signal,
  ViewChild, WritableSignal
} from '@angular/core';
import {NgbCarousel, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {Image, Maybe, WineProduct} from '@core/interfaces/generated/graphql';
import {NgClass, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {ProductWidgetComponent} from '@layout/components/product-widget/product-widget.component';
import {ProductCarouselComponent} from './product-image-carousel/product-image-carousel.component';
import {
  ProductImageThumbnailCarouselComponent
} from './product-image-thumbnail-carousel/product-image-thumbnail-carousel.component';
import {TrackEventService} from '@core/services/track-event.service';
import {SplitTestService} from '@core/services/split-test.service';

export type Product360Image = { id: string, vismaId: string, scriptId: string };

export type ProductImage = {
  id: string,
  original: string,
  large: string,
  small: string,
  scriptId?: string,
  is360: boolean,
}

@Component({
  selector: 'app-product-images',
  templateUrl: './product-images.component.html',
  styleUrls: ['./product-images.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgOptimizedImage, NgForOf, NgbCarousel, ProductWidgetComponent, ProductCarouselComponent, ProductCarouselComponent, ProductImageThumbnailCarouselComponent],
})

export class ProductImagesComponent {
  @ViewChild('imageContainer') imgContainer: ElementRef<HTMLDivElement> | undefined;
  public carouselImageArray: WritableSignal<ProductImage[] | null> = signal(null);
  private modalReference: NgbModalRef;
  product = input<WineProduct | undefined>();
  get360Image = computed(() => {
    return this.get360ImageByVismaId(this.product()?.vismaProductNumber ?? '');
  });
  images = computed<ProductImage[] | undefined>(() => {
    const images = this.product()?.images;
    const image360 = this.get360Image();
    const splitTestVersion = this.splitTestService.getSplitTestVersion('product360Image');
    const imagesFormatted = images?.map(image => {
      return {
        id: image?.id ?? '',
        original: image?.original ?? '',
        large: image?.large ?? '',
        small: image?.small ?? '',
        scriptId: '',
        is360: false
      }
    });
    if (splitTestVersion() === 2 && image360 && imagesFormatted && imagesFormatted.length > 0) {
      const imagesWithoutFirst = imagesFormatted.filter((image, index) => index !== 0);
      return [{
        id: image360.id,
        original: `//cdn.orbitvu.co/share/${image360.id}/still/view?height=1000&max_width=1000`,
        large: `//cdn.orbitvu.co/share/${image360.id}/still/view?height=600&max_width=400`,
        small: `//cdn.orbitvu.co/share/${image360.id}/still/view?height=50&max_width=50`,
        scriptId: image360.scriptId,
        is360: true
      }, ...imagesWithoutFirst ?? []];
    }
    return imagesFormatted;
  });

  constructor(
    private modalService: NgbModal,
    private trackEventService: TrackEventService,
    private splitTestService: SplitTestService
  ) {
    effect(() => {
      const images = this.images();
      if (images && images[0]?.id) {
        const carouselImageArray = this.carouselImageArray();
        const diff = images.filter(image => !carouselImageArray?.find(carouselImage => carouselImage.id === image.id));
        if (carouselImageArray === null || diff.length > 0) {
          this.carouselImageArray.set(images);
        }
      }
    }, {allowSignalWrites: true});
  }

  public _activeImageId = signal<string | null>(null);

  activeImageIdSignal = computed(() => {
    const images = this.images();
    if (this._activeImageId() === null && images?.length) {
      return images[0]?.id;
    }
    return this._activeImageId();
  });

  activeImage: Signal<Maybe<Image> | undefined> = computed(() => {
    const product = this.product();
    if (!product) {
      return undefined;
    }
    return this.images()?.find(image => image.id === this.activeImageIdSignal());
  });

  get activeImageId(): string | null {
    return this.activeImageIdSignal()
  }

  set activeImageId(value: string) {
    this._activeImageId.set(value);
  }

  openImageProductModal() {
    this.trackEventService.trackClickEvent('product_image_modal_open', {
      image_id: this.activeImageId,
    })
    import('../../../../../../layout/components/product-image-modal/product-image-modal.component').then(comp => {
      this.modalReference = this.modalService.open(comp.ProductImageModalComponent, {
        modalDialogClass: 'modal-dialog-bottom modal-large'
      });
      this.modalReference.componentInstance.product = this.product();
      this.modalReference.componentInstance.image = this.activeImage();
    })
  }

  get360ImageByVismaId(vismaId: string) {
    const images: Product360Image[] = [
      {id: 'qYuHaHLPYv5skkAtBZA9cb', vismaId: '343600623', scriptId: '6238019'},
      {id: 'qQFnmA645PDGGqJnF6GDWj', vismaId: '56110123', scriptId: '6238029'},
      {id: 'RgQTJKJkAuPg34qZPbsAeE', vismaId: '3313404122', scriptId: '6238035'},
      {id: 'iWmQnVFd4BCKaL9BE5ZkDd', vismaId: '333551123', scriptId: '6238036'},
      {id: '227HrtfeFZJzbB43VGi4PM', vismaId: '396702nv', scriptId: '6238037'},
      {id: '9q2oLgBCFTGLzTBbhznG7a', vismaId: '3446004nv', scriptId: '6238038'},
      {id: 'PBK2Jk45apJy4bJqdyvr9j', vismaId: '392380120', scriptId: '6238039'},
      {id: 'tPmh2UY23BdpcNQwtrLze5', vismaId: '342250122', scriptId: '6238040'},
      {id: 'p6D4ionXLyPMKhm4brkwtB', vismaId: '332501520', scriptId: '6238041'},
      {id: 'dMWqkimkKMQepF4mpRJX77', vismaId: '392385322', scriptId: '6238045'},
      {id: 'cUR3Q4qcZAEjFTT3rUU67m', vismaId: '334011008', scriptId: '6238054'},
      {id: 'MWcyT2XoYf5yPnvuWJ8rDm', vismaId: '391702422', scriptId: '6238060'},
    ];
    return images.find(image => image.vismaId === vismaId);
  }
}
